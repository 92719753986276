import React, { useEffect, useRef } from 'react';

const WaterDrop = ({ data }) => {
    // console.log(data.length);

    const canvasRef = useRef(null);
    const rows = 4;
    const cols = 5;
    const circleDiameter = 20;
    const colors = {
        buy: '#18c07c',  // Màu xanh
        sell: '#dc3545', // Màu đỏ
        none: '#2f3342'  // Màu nâu (khi đủ 60 phần tử)
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Set canvas dimensions
        canvas.width = cols * circleDiameter;
        canvas.height = rows * circleDiameter;

        // Draw circles
        data.forEach((value, index) => {
            const x = (index % cols) * circleDiameter + circleDiameter / 2;
            const y = Math.floor(index / cols) * circleDiameter + circleDiameter / 2;

            // Nếu mảng đủ 60 phần tử, màu nâu sẽ được sử dụng
            // const color = data.length === 60 ? colors.brown : (value ? colors.true : colors.false);
            let color;
            switch (value) {
                case 1:
                    color = colors.buy;
                    break;
                case 2:
                    color = colors.sell;
                    break;
                default:
                    color = colors.none;
                    break;
            }

            ctx.beginPath();
            ctx.arc(x, y, circleDiameter / 2 - 2, 0, 2 * Math.PI);
            ctx.fillStyle = color;
            ctx.fill();
            ctx.closePath();
        });
    }, [data]);

    return (
        <canvas ref={canvasRef} />
    );
};

export default WaterDrop;
