import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import React, { useEffect, useState } from "react";
import { formatNumber, formatTimestamp } from "service";
import { getHistory } from "service/indexNew";

function DepositHistory() {
  const [historyData, setHistoryData] = useState([]);
  const [typeHistory, setTypeHistory] = useState('all');
  useEffect(async () => {
    let data = await getHistory(typeHistory);
    if (data) {
      setHistoryData(data.data)
    }
    // console.log(data);
  }, []);
  const changeHistory = async (types) => {
    setTypeHistory(types)
    let data = await getHistory(types);
    if (data) {
      setHistoryData(data.data)
    }
  }
  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D", padding: 0 }}
        className="body customers_page flex-grow-1 px-3 p-0"
      >
        <div className="container-lg ibgate-page">
          <h1>History</h1>

          <div className="mt-4 mb-4">
            <button className="lsldoaowda" onClick={() => { changeHistory('all') }}>All</button>
            <button className="lsldoaowda" onClick={() => { changeHistory('deposit') }}>Deposit</button>
            <button className="lsldoaowda" onClick={() => { changeHistory('withdraw') }}>Withdraw</button>
            <button className="lsldoaowda" onClick={() => { changeHistory('bet') }}>Trade</button>
            {/* <button className="lsldoaowda" onClick={() => { changeHistory('tranfer') }}>Transfer</button> */}
            <button className="lsldoaowda" onClick={() => { changeHistory('lending') }}>Bot Trade</button>
            {/* <button className="lsldoaowda" onClick={() => { changeHistory('marketing') }}>Marketing</button> */}
            <button className="lsldoaowda" onClick={() => { changeHistory('commission') }}>Commission</button>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="mb-4">
                <div className="table-responsive listSubAccounts">
                  <table
                    style={{
                      backgroundColor: "#0D102D",
                    }}
                    className="table border mb-0 "
                  >
                    <thead className="table-light fw-semibold">
                      <tr className="align-middle">
                        <th className="th_tb_else text-center">
                          Date created
                        </th>
                        <th className="th_tb_else text-center">
                          Type transactions
                        </th>
                        {/* <th className="th_tb_else text-center">
                            Deposit code
                          </th> */}
                        <th className="th_tb_else text-center">
                          Amount of money
                        </th>
                        <th className="th_tb_else text-center">Status transactions</th>

                        {/* <th className="th_tb_else text-center">
                            Your actions
                          </th> */}
                      </tr>
                    </thead>
                    <tbody className="getAllSubUser">
                      {
                        historyData?.map((e) => {
                          return (
                            <tr
                              style={{
                                borderBottom: "1px solid #121a3e",
                              }}
                              id="boxSub_1138"
                              className="align-middle"
                            >
                              <td className="text-center">{formatTimestamp(e?.created)} </td>
                              <td className="text-center">
                                <span
                                  style={{
                                    color: "#f6792c",
                                  }}
                                >
                                  {e?.type?.replaceAll('lending', 'Bot Trade')} ({e?.description?.type?.replaceAll('Lending', 'Bot Trade')})
                                </span>
                              </td>
                              {/* <td className="text-center">8T6DOPNF53Q0YV9MXL42 </td> */}

                              <td className="text-center">{formatNumber(e?.amount)} {e?.symbol} </td>
                              <td className="text-center">
                                <span
                                  style={{
                                    color: "#f6792c",
                                  }}
                                >
                                  {e?.status}
                                </span>
                              </td>

                              {/* <td className="text-center">
                                  <a
                                    style={{
                                      color: "#03A9F4",
                                    }}
                                    href="https://bfflendinglewlew.info/gate/control/deposit/8T6DOPNF53Q0YV9MXL42"
                                  >
                                    Update
                                  </a>
                                </td>
                                <td className="text-center">
                                  <a
                                    style={{
                                      color: "#FFC107",
                                    }}
                                    href="https://bfflendinglewlew.info/gate/control/deposit-cancel?id=1138"
                                  >
                                    Cancel
                                  </a>
                                </td> */}
                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </table>
                </div>
              </div>
              <div className="nav-pa"></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default DepositHistory;
