import React, { useState } from "react";
import "./styles.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";

function SidebarV2() {
  const state = useSelector((state) => state);
  const [showFunds, setShowFunds] = useState(false);
  const [showProfile, setProfile] = useState(false);
  const [showGame, setGame] = useState(false);
  const history = useHistory();
  const handleToggleSidebar = () => {
    var sidebar = document.getElementById("sidebar");
    if (sidebar) {
      if (sidebar.classList.contains("show")) {
        sidebar.classList.remove("show");
      } else {
        sidebar.classList.add("show");
      }
    }
  };
  return (
    <div
      style={{ backgroundColor: "#0D102D" }}
      className="sidebar sidebar-dark sidebar-fixed"
      id="sidebar"
    >
      <div className="sidebar-brand d-none d-md-flex">
        <div className="sidebar-brand-full">
          <img
            height="35"
            src="images/logoBff.png"
            alt=""
          />
        </div>
        <div className="sidebar-brand-narrow">
          <img
            height="30"
            src="images/logoBff.png"
            alt=""
          />
        </div>
      </div>
      <ul className="sidebar-nav" data-coreui="navigation" data-simplebar="">
        <li className="ibPageNav ></li>nav-item">
          <a
            className="nav-link"
            onClick={() => {
              history.push("/");
            }}
          >
            <span className="nav-icon">
              <img
                height="20"
                src="images/logoBff.png"
                alt=""
              />
            </span>
            Dashboard
          </a>
        </li>

        <li className="ibPageNav nav-item">
          <a
            className="nav-link"
            onClick={() => {
              history.push("/lending");
            }}
          >
            <span className="nav-icon">
              <img
                height="20"
                src="images/bff/investments-balance.png"
                alt=""
                className="icon"
              />{" "}
            </span>
            Bot Trade
          </a>
        </li>

        {/* <li className="nav-group">
    <a href="https://bfflendinglewlew.info/gate/control/lending" className="nav-link nav-group-toggle" >
      <span className="nav-icon">
      <img height="20" src="images/bff/investments-balance.png" alt="" className="icon">
      </span>
      Staking 
    </a>	
    <ul className="nav-group-items">            
      <li className="nav-item"><a className="nav-link pdl-25" href="https://bfflendinglewlew.info/gate/control/lending"><span className="nav-icon"></span> Investment</a></li>	
      <li className="nav-item"><a className="nav-link pdl-25" href="https://bfflendinglewlew.info/gate/control/withdraw-invest"><span className="nav-icon"></span> Withdraw</a></li>	

      <li className="nav-item"><a className="nav-link pdl-25" href="https://bfflendinglewlew.info/gate/control/withdraw-invest-history"><span className="nav-icon"></span> Withdrawal history</a></li>	

      <li className="nav-item"><a className="nav-link pdl-25" href="https://bfflendinglewlew.info/gate/control/deposit-invest-history"><span className="nav-icon"></span> Deposit history</a></li>
      
      <li className="nav-item"><a className="nav-link pdl-25" href="https://bfflendinglewlew.info/gate/control/withdraw-invest?type=transfer"><span className="nav-icon"></span> Transfer money to game wallet</a></li>					
    </ul>
  </li>  */}

        <li className="ibPageNav nav-item">
          <a
            className="nav-link"
            onClick={() => {
              history.push("/community");
            }}
          >
            <span className="nav-icon">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAMAAADgvdz9AAAAPFBMVEVHcEz///////////////////////////////////////////////////////////////////////////+PybD1AAAAE3RSTlMAEkyfbJLmxiN8PFqrLwf0ttQZth+ORQAAANlJREFUGBm1wUly2zAAAMEBiJU7Of//a8BKLJXsiy/p5vfmOQD7VK7IT5OWBkk9dt6WaWpwO3ToDjcvs1p2mkOH7hB4SQ6N4JBgUSv30vgrOTRY1QZUTYTDsuYFiA47RO0Q+qVOBB8MVTttKrrO6XA4t9AdJmA7dUm+1UYsPmZY/XRF5qrW0wLRD8dCONWjgxmyw9pS8ZEhO+QbyBEmrRsBYrXeJIdz40s9Iq16LbCzXWqNvM3sk4/St1UtiQ/JofpP3/mQ1XNjy4e6Nr6pXguP0NeZH3Lmv/kD77oShR7M72IAAAAASUVORK5CYII="
                alt=""
                className="icon"
              />{" "}
            </span>
            Trade
          </a>
        </li>
        <li className="ibPageNav nav-item">
          <a
            className="nav-link"
            onClick={() => {
              history.push("/history");
            }}
          >
            <span className="nav-icon">
              <img
                height="20"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAfCAMAAABav4S9AAAANlBMVEVHcEz///////////////////////////////////////////////////////////////////+GUsxbAAAAEXRSTlMAOpiqxh3Y9AcRheZWargrSPTFqUQAAADfSURBVBgZfcEJcoMwFETB0b4gAe/+lw2YVFKxf9Stv5obc7imf7UZeeQpU+2FX1UGl7nEPsfssXgZRoHSkxYGsCetHAW8lmqGoLUJscrS9K1ucMjSyqnHgCBTJ1e9eDhlSQW6XjKlyuKBcuhSIcpycItSSw7iOZo+BF6mPI9D7xq3LXulwi3oU5UHp0vnUpIsOyRdaga8TBtFLw5Kk8VB0MPTZQow9Gh7k8XBVrVUM0wt1R2iltoOW9LKuUFx+lTPpFsbEciHDAHyHmLmFposmR/xlC3NfQNK7IeWWtWbLzVFDZ7P27WEAAAAAElFTkSuQmCC"
                alt=""
                className="icon"
              />{" "}
            </span>
            History
          </a>
        </li>
        <li className="ibPageNav nav-item">
          <a
            className="nav-link"
            onClick={() => {
              history.push("/network");
            }}
          >
            <span className="nav-icon">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAMAAADgvdz9AAAAOVBMVEVHcEz///////////////////////////////////////////////////////////////////////99PJZNAAAAEnRSTlMAPoxtxOIl7bcypH4IFdVRXUqO3N6PAAAAr0lEQVQYGZXBW5JCIQwFwAMkJOF5b/a/WEtLLcCfmW585FR0sAgPKsmwM/INBaz8B1bVDxGbXEn8TagafrRsYc5gueHUZ8OizY6VuJR63ZazhasWdsFK/UDYXCr+JXrh1GymGmNN0zpOGZuMjbqmO/cGtJ7vpE5Ysb8Is/iLYNUi+4JjxyGkWIpqKTEF/E8cMXS89VBHxMqfmFSLEvsTVka+oYCdpaKDRXhoSYa/eQBQ3A45kl3N1wAAAABJRU5ErkJggg=="
                alt=""
                className="icon"
              />{" "}
            </span>
            Network
          </a>
        </li>

        {/* <li className="nav-group">
    <a className="nav-link nav-group-toggle" >
      <span className="nav-icon">
      <img  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAMAAADgvdz9AAAAPFBMVEVHcEz///////////////////////////////////////////////////////////////////////////+PybD1AAAAE3RSTlMAEkyfbJLmxiN8PFqrLwf0ttQZth+ORQAAANlJREFUGBm1wUly2zAAAMEBiJU7Of//a8BKLJXsiy/p5vfmOQD7VK7IT5OWBkk9dt6WaWpwO3ToDjcvs1p2mkOH7hB4SQ6N4JBgUSv30vgrOTRY1QZUTYTDsuYFiA47RO0Q+qVOBB8MVTttKrrO6XA4t9AdJmA7dUm+1UYsPmZY/XRF5qrW0wLRD8dCONWjgxmyw9pS8ZEhO+QbyBEmrRsBYrXeJIdz40s9Iq16LbCzXWqNvM3sk4/St1UtiQ/JofpP3/mQ1XNjy4e6Nr6pXguP0NeZH3Lmv/kD77oShR7M72IAAAAASUVORK5CYII=" alt="" className="icon">
      </span> Marketing
       
    </a>	
    <ul className="nav-group-items">            
      <li className="nav-item"><a className="nav-link pdl-25" href="https://bfflendinglewlew.info/gate/control/community"><span className="nav-icon"></span> Statistical</a></li>	
      <li className="nav-item"><a className="nav-link pdl-25" href="https://bfflendinglewlew.info/gate/control/deposit-community"><span className="nav-icon"></span> Deposit</a></li>	
      
    </ul>
  </li>  */}
        {/* <li className="nav-group">
          <a
            className="nav-link nav-group-toggle"
            onClick={() => {
              setShowFunds(!showFunds);
            }}
          >
            <span className="nav-icon">
              <img
                height="20"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAfCAMAAABav4S9AAAANlBMVEVHcEz///////////////////////////////////////////////////////////////////+GUsxbAAAAEXRSTlMAOpiqxh3Y9AcRheZWargrSPTFqUQAAADfSURBVBgZfcEJcoMwFETB0b4gAe/+lw2YVFKxf9Stv5obc7imf7UZeeQpU+2FX1UGl7nEPsfssXgZRoHSkxYGsCetHAW8lmqGoLUJscrS9K1ucMjSyqnHgCBTJ1e9eDhlSQW6XjKlyuKBcuhSIcpycItSSw7iOZo+BF6mPI9D7xq3LXulwi3oU5UHp0vnUpIsOyRdaga8TBtFLw5Kk8VB0MPTZQow9Gh7k8XBVrVUM0wt1R2iltoOW9LKuUFx+lTPpFsbEciHDAHyHmLmFposmR/xlC3NfQNK7IeWWtWbLzVFDZ7P27WEAAAAAElFTkSuQmCC"
                alt=""
                className="icon"
              />
            </span>
            Funds
          </a>
          {showFunds && (
            <ul className="">
              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  onClick={() => {
                    history.push("/deposit");
                  }}
                >
                  <span className="nav-icon"></span> Deposit USDT
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  onClick={() => {
                    history.push("/withdraw");
                  }}
                >
                  <span className="nav-icon"></span> Withdraw USDT
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  onClick={() => {
                    history.push("/swap");
                  }}
                >
                  <span className="nav-icon"></span> Swap
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  onClick={() => {
                    history.push("/swap-history");
                  }}
                >
                  <span className="nav-icon"></span> SWAP History
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  onClick={() => {
                    history.push("/deposit-history");
                  }}
                >
                  <span className="nav-icon"></span> Deposit history
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  onClick={() => {
                    history.push("/staking-history");
                  }}
                >
                  <span className="nav-icon"></span> Investment history
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  onClick={() => {
                    history.push("/transfer-money");
                  }}
                >
                  <span className="nav-icon"></span> Internal money transfer
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  onClick={() => {
                    history.push("/transfer-money-history");
                  }}
                >
                  <span className="nav-icon"></span> Internal money transfer
                  history
                </a>
              </li></ul>
          )}
        </li> */}
        {/* <li className="ibPageNav nav-item">
          <a
            className="nav-link"
            onClick={() => {
              history.push("/ib-level");
            }}
          >
            <span className="nav-icon">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAMAAADgvdz9AAAAOVBMVEVHcEz///////////////////////////////////////////////////////////////////////99PJZNAAAAEnRSTlMAPoxtxOIl7bcypH4IFdVRXUqO3N6PAAAAr0lEQVQYGZXBW5JCIQwFwAMkJOF5b/a/WEtLLcCfmW585FR0sAgPKsmwM/INBaz8B1bVDxGbXEn8TagafrRsYc5gueHUZ8OizY6VuJR63ZazhasWdsFK/UDYXCr+JXrh1GymGmNN0zpOGZuMjbqmO/cGtJ7vpE5Ysb8Is/iLYNUi+4JjxyGkWIpqKTEF/E8cMXS89VBHxMqfmFSLEvsTVka+oYCdpaKDRXhoSYa/eQBQ3A45kl3N1wAAAABJRU5ErkJggg=="
                alt=""
                className="icon"
              />{" "}
            </span>
            Network
          </a>
        </li> */}
        {/* <!-- <li className="ibPageNav nav-item">
    <a href="https://bfflendinglewlew.info/gate/control/commission-history" className="nav-link" >
    <span className="nav-icon">
    <img  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAMAAADgvdz9AAAAPFBMVEVHcEz///////////////////////////////////////////////////////////////////////////+PybD1AAAAE3RSTlMAEkyfbJLmxiN8PFqrLwf0ttQZth+ORQAAANlJREFUGBm1wUly2zAAAMEBiJU7Of//a8BKLJXsiy/p5vfmOQD7VK7IT5OWBkk9dt6WaWpwO3ToDjcvs1p2mkOH7hB4SQ6N4JBgUSv30vgrOTRY1QZUTYTDsuYFiA47RO0Q+qVOBB8MVTttKrrO6XA4t9AdJmA7dUm+1UYsPmZY/XRF5qrW0wLRD8dCONWjgxmyw9pS8ZEhO+QbyBEmrRsBYrXeJIdz40s9Iq16LbCzXWqNvM3sk4/St1UtiQ/JofpP3/mQ1XNjy4e6Nr6pXguP0NeZH3Lmv/kD77oShR7M72IAAAAASUVORK5CYII=" alt="" className="icon">
    </span>History Of Receiving Commissions				</a>	
  </li> --> */}

        {/* <li className="ibPageNav nav-item">
          <a
            className="nav-link"
            onClick={() => {
              history.push("/referral");
            }}
          >
            <span className="nav-icon">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAMAAADgvdz9AAAANlBMVEVHcEz9/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f39/f04qCpMAAAAEXRSTlMAOllqnIfQ6eANKh7CTvWwqd1r5+4AAACzSURBVBgZrcFZdsQgDATABiQssdju+182Jp43AWfylyrcXM2k4i8HL9nxWee3BHd8oByOILblhF+Ul1YzB8FT4CUIbxFPSlrZeWt4iDGeGvhijkUiJfbOF8Oi7hyS8XZioWQXtqq8BSyMsUIdODkkrHqIewKC1Hg0CV0ci0gywWip1KJkc0xKJvdcipHcOQgmjoO5amp8C5glWuFMMPPMcHKSsai5FeOPDSvXXtr2lvEfvgBPxw5+d+4bnQAAAABJRU5ErkJggg=="
                alt=""
                className="icon"
              />{" "}
            </span>
            Referral Links
          </a>
        </li> */}
        {/* <li className="nav-group">
          <a
            className="nav-link nav-group-toggle"
            onClick={() => {
              setProfile(!showProfile);
            }}
          >
            <span className="nav-icon">
              <img
                height="20"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAdBAMAAABPisE/AAAALVBMVEVHcEz////////////////////////////////////////////////////////NXt0CAAAADnRSTlMA3gZq81LEFaEn1DTpf6yOycYAAACgSURBVBjTY2AAAfZrZ3ILGOCAKeTdu3euCD7zOxAwgPPngfkv4fw+MP8FXLsfmP9EAcrnfAcBE3Dw2aF8uAvWgbmv4ObbgfmP4Xw1MD8JzmcHaXiF5AFdv3dPLjEgAVXjIAbygbZF4hmx5k0w70VA/dcK9iCT4TsYEAYJ7HqHAKsZGLj9kPhPNjAovkMGhxjiUPhPoX6FgccE+YGCyEAUAKZCuYIlZJzpAAAAAElFTkSuQmCC"
                alt=""
                className="icon"
              />{" "}
            </span>
            Profile
          </a>
          {showProfile && (
            <ul className="">
              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  onClick={() => {
                    history.push("/profile");
                  }}
                >
                  <span className="nav-icon"></span> My Profile
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  onClick={() => {
                    history.push("/change-password");
                  }}
                >
                  <span className="nav-icon"></span> Change Client Portal
                  Password
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  onClick={() => {
                    history.push("/security");
                  }}
                >
                  <span className="nav-icon"></span> Password Level 2
                </a>
              </li>
            </ul>
          )}
        </li> */}
        {/* <li className="nav-group">
          <a
            className="nav-link nav-group-toggle"
            onClick={() => {
              setGame(!showGame);
            }}
          >
            <span className="nav-icon">
              <img
                height="20"
                src="images/bff/game.png"
                alt=""
                className="icon"
              />{" "}
            </span>
            Game
          </a>
          {showGame && (
            <ul className="">
              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  href={`/static/number.html`}
                >
                  <span className="nav-icon"></span> Lucky number
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  href={`/static/spin.html`}
                >
                  <span className="nav-icon"></span> Lucky spin
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link pdl-25"
                  href="/static/bigsmall.html"
                >
                  <span className="nav-icon"></span> Big Small
                </a>
              </li>
            </ul>
          )}
        </li> */}
      </ul>
      {/* <a
        style={{
          background: "#073a77",
          display: "block",
          width: "80%",
          textAlign: "center",
          margin: "0 auto 30px",
          padding: "10px 0",
          fontWeight: "bold",
          borderRadius: "5px",
          color: "#fff",
        }}
        href="https://bfflendinglewlew.info//logout"
      >
        Logout
      </a> */}
      <button
        className="sidebar-toggler"
        type="button"
        data-coreui-toggle="unfoldable"
        onClick={handleToggleSidebar}
      ></button>
    </div>
  );
}

export default SidebarV2;
