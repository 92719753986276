import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import LoadingButton from "components/lib/loading";
import React, { useEffect, useRef, useState } from "react";
import { checkApproveStaking, deposit, submitApproveStaking } from "service/indexNew";

function Deposit() {
  const [amount, setAmount] = useState(0);
  const [isApprove, setIsApprove] = useState(false);
  const [loading, setLoading] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    const countdown = () => {
      getData()
    };
    timerRef.current = setInterval(countdown, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const getData = async () => {
    let checkApp = await checkApproveStaking();
    // console.log('checkApp', checkApp);
    setIsApprove(checkApp?.stt)
  }



  const _approve = async () => {
    setLoading(true)
    // console.log('start');
    let data = await submitApproveStaking();
    // console.log('data');
    if (!data) {

    }
    setLoading(false);
    // setIsApprove(data)
  }

  const _deposit = async () => {
    setLoading(true)
    let res = await deposit(amount);
    // console.log(data);
    // console.log(res);
    if (res) {
      alert('successfully')
    }
    setAmount(0);
    setLoading(false);
  }
  // console.log(isApprove);
  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="body customers_page flex-grow-1 px-3">
          <div className="container-lg ibgate-page">
            <h1>Crypto Recharge Gate</h1>

            <div className="row deposit-page deposit-page-crypto">
              <div className="header_page">
                <span
                  data-title="Recharge form (USDT)"
                  data-method="USDT-BEP20"
                  data-id="usdt-bep20"
                  className="live_bank active"
                >
                  USDT (BEP20){" "}
                  <img
                    height="25"
                    src="images/logoB2ff.png"
                    alt=""
                  />
                </span>
              </div>
              {/* <div className="content">
                <div id="usdt-bep20" className="boxBank active">
                  <p>Deposit USDT BEP-20</p>
                  <ul>
                    <li>
                      1.&nbsp;&nbsp; Complete the form below and click "Submit"
                    </li>
                    <li>
                      2.&nbsp;&nbsp; The system will redirect you to a page
                      showing the USDT wallet address on the BEP-20 network
                    </li>
                    <li>
                      3.&nbsp;&nbsp; Log in to your USDT address and make a
                      transfer to your new USDT wallet address
                    </li>
                    <li>
                      4.&nbsp;&nbsp; Provide HAS transaction code after you make
                      money transfer.
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="form_bank">
                <h2 className="getTitleBank">Recharge form USDT BEP-20</h2>
                <form action="#">
                  <div className="row form_content">
                    <div className="col-md-6">
                      <div className="form_group">
                        <label for="">
                          <i className="fas fa-star-of-life"></i>
                          <span className="getTitleBank">
                            {" "}
                            Enter the amount (USDT)
                          </span>
                        </label>
                        <input
                          name="money"
                          data-type="money"
                          required
                          min="50"
                          data-exchange="25000"
                          data-currency="USDT"
                          data-min="50"
                          className="getMoneyValdUsd form-control form-control-lg"
                          type="text"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          placeholder="Enter the amount"
                        />
                      </div>
                    </div>


                  </div>
                  {
                    loading ?
                      <LoadingButton type="button" className="ant-btn ant-btn-primary" />
                      :
                      (
                        !isApprove ?
                          <button onClick={() => { _approve() }}>Approve</button>
                          :
                          <button onClick={() => { _deposit() }}>Confirm</button>
                      )
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Deposit;
