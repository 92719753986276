import React, { useEffect, useRef, useState } from 'react'
import './styles.css';
import Web3 from 'web3';
import { checkRefUser, domains, getInfoUserss } from 'service/indexNew';
import UpdateAffiliateProgram from 'components/lib/modalAffiliate';
import { NotificationManager } from 'react-notifications';
import Notification from 'components/lib/notification';
import { formatNumber } from 'service';
import ModalPass2 from 'components/lib/modalPass2';
// import { signTypedData } from '@wagmi/core'
// import { config } from './config'
// import { types } from './typedData'

// const domains = 'http://113.177.22.100:2003'

function HeaderV2() {
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState({});
  const [sttMoldelAffiliate, setSttMoldelAffiliate] = useState(false);
  const [sttMoldelPass2, setMoldelPass2] = useState(false);
  
  const [ref, setRef] = useState("");
  const [branch, setBranch] = useState("");
  const [pass2, setPass2] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [notificationObj, setNotificationObj] = useState({
    show: false,
    stt: true,
    msg: ""
  });
  const timerRef = useRef(null);

  const connect = async (val) => {
    // NotificationManager.warning("fail")

    // return
    // console.log(val);
    if (window.ethereum) {
      window.ethereum.enable().then(async (accounts) => {
        setUserAddress(accounts[0].toLocaleLowerCase())
        console.log(accounts);
        console.log(dataUser);
        // console.log();
        let res = await checkRefUser(accounts[0].toLocaleLowerCase());
        console.log(res);
        // setSttMoldelAffiliate(true)
        if (res.status != 200) {
          setSttMoldelAffiliate(true)
        } else {
          setMoldelPass2(true)
          // getChallenge(accounts[0].toLocaleLowerCase())
        }
        // return
        // getChallenge(accounts)
      });
    }
  }

  const openNotic = (stt, msg) => {
    setNotificationObj({ show: true, stt, msg })
    setTimeout(() => {
      setNotificationObj({ show: false, stt, msg })
    }, 5000);
  };

  useEffect(() => {
    const countdown = () => {
      getData()
    };
    timerRef.current = setInterval(countdown, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);
  const getData = async () => {
    let data = await getInfoUserss();
    setDataUser(data)
  }
  useEffect(() => {
    const url = window.location.href;
    const lastSlashIndex = url.lastIndexOf("/");
    const addressWithRefId = url.substring(lastSlashIndex + 1);
    const [refId, queryString] = addressWithRefId.split("?");
    const params = new URLSearchParams(queryString);
    const ref = params.get("ref");
    const branch = params.get("branch");
    if (ref) {
      setRef(ref);
    }
    if (branch) {
      setBranch(branch);
    }
    console.log(ref, branch);
  }, []);



  // //new
  // useEffect(async () => {
  //   let data = await getInfoUserss();
  //   setDataUser(data)
  //   console.log(data);
  //   setInterval(async () => {
  //     let data = await getInfoUserss();
  //     setDataUser(data)
  //     // console.log(data);
  //   }, 1000);
  // }, []);

  const getChallenge = async (accounts) => {
    console.log('runnnn');
    
    let web3 = window.web3;

    if (typeof web3 !== "undefined") {
      web3 = new Web3(web3.currentProvider);
    } else {
      console.log("No web3 instance injected, make sure MetaMask is installed.");
    }
    // alert(1)

    // if (Web3.givenProvider && window.localStorage.getItem("providerType") != 'wallet') {
    //   web3 = new Web3(Web3.givenProvider);
    // } else {
    //   web3 = await getWeb3Provider(Web3.givenProvider);
    // }

    // console.log(accounts)
    const res = await fetch(
      `${domains}/auth/${accounts.toLowerCase()}`
    );
    // alert(2)
    signChallenge(web3, await res.json(), accounts)
  };

  const signChallenge = async (web3, challenge, accounts) => {
    // web3.currentProvider.send(
    //   {
    //     method: "eth_signTypedData",
    //     params: [challenge, accounts],
    //     from: accounts
    //   },
    //   (error, res) => {
    //     // alert(error)
    //     if (error) return alert(error);
    //     console.log(res);
    //     // this.setState({ signature: res.result });
    //     verifySignature(challenge, res.result, accounts)
    //   }
    // );
    verifySignature(challenge, "res.result", accounts)
  };
  const verifySignature = async (challenge, signature, accounts) => {
    // const { challenge, signature, accounts } = this.state;
    // console.log(`${domains}/auth/${challenge[1].value}/${signature}?ref=admin&branch=0`);
    // const res = await fetch(
    //     `${domains}user/auth/${challenge[1].value}/${signature}?ref=admin&branch=0`
    // );
    const res = await fetch(`${domains}/auth/${challenge[1].value}/${signature}?ref=${ref}&pass2=${pass2}&address=${accounts}`, {
      method: "GET",
    }).then((res) => res.json());
    console.log(res);
    if (res.code == 200) {
      window.localStorage.setItem('access_token', res.data);
      setSttMoldelAffiliate(false)
      setMoldelPass2(false)
      // console.log('----------------', res?.message);
      // if (res?.message) {
      //   openNotic('success', res?.message)
      // } else {
      //   openNotic('error', "fail")
      // }
    } else {
      console.log('----------------', res?.message);
      if (res?.message) {
        openNotic('error', res?.message)
      } else {
        openNotic('error', "fail")
      }
      console.log("Signature not verified");
    }
  };

  const logout = () => {
    window.localStorage.clear()
  }
  const handleToggleSidebar = () => {
    var sidebar = document.getElementById("sidebar");
    if (sidebar) {
      if (sidebar.classList.contains("show")) {
        sidebar.classList.remove("show");
      } else {
        sidebar.classList.add("show");
      }
    }
  };
  return (
    <header style={{ backgroundColor: '#0D102D' }} className="header header-sticky">
      <div className="container-fluid">
        <button
          className="header-toggler px-md-0 me-md-3"
          type="button"
          onClick={handleToggleSidebar}

        >
          <i className="hamburger align-self-center"></i>
        </button>
        <ul className="header-nav ms-auto">
          <li className="nav-item">
            {dataUser?.address ?
              <a className="Account_root__3_VoB" onClick={logout}>
                <div className="">
                  <div className="Account_address__3k1-V">{dataUser?.address?.slice(0, 6)}...{dataUser?.address?.slice(-4)}</div>
                  <div className="Account_balance__1zr_K">{formatNumber((dataUser?.balanceUsdt))} USDT</div>
                </div>
                <div className="Account_avatar__3pqlg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    id="logout-icon"
                  >
                    <path d="M16 17l5-5-5-5M9 12h11"></path>
                  </svg>
                </div>
              </a>
              :
              <div className="connectButton" onClick={connect}>Connect</div>
            }
            {/* <button onClick={connect}>Connect</button> */}
          </li>

        </ul>
      </div>
      {
        sttMoldelAffiliate ?
          <UpdateAffiliateProgram changeMDWLST={(_sstt) => { setSttMoldelAffiliate(_sstt) }} refs={ref} branch={branch} onChangeRef={(e) => { setRef(e) }}
            onChangeBranch={(e) => { setBranch(e) }} fcSubmit={() => { getChallenge(userAddress) }}
            pass2={pass2} onChangePass2={(e) => { setPass2(e) }} />
          : null
      }
      {
        sttMoldelPass2 ?
          <ModalPass2 changeMDWLST={(_sstt) => { setMoldelPass2(_sstt) }}
            fcSubmit={() => { getChallenge(userAddress) }}
            pass2={pass2} onChangePass2={(e) => { setPass2(e) }} />
          : null
      }

      {
        notificationObj?.show ?
          <Notification status={notificationObj?.stt} msg={notificationObj?.msg} />
          : null
      }

    </header>
  )
}

export default HeaderV2
