import SidebarV2 from "components/Sidebar/SidebarV2";
import React, { useEffect, useRef, useState } from "react";
import HeaderV2 from "components/Header/HeaderV2";
import Layout from "components/layout/Layout";
import { get_tree, getInfoUserss } from "service/indexNew";
import { formatNumber } from "service";
import { useSelector } from "react-redux";
import TreeMenu from 'react-simple-tree-menu';

function Dashboard() {
  const state = useSelector((state) => state);
  const [dataUser, setDataUser] = useState({});
  const timerRef = useRef(null);
  const [treeView, setTreeView] = useState([]);

  useEffect(() => {
    // console.log(dataUser);
    const arr = [];
    if (dataUser) {
      get_tree(dataUser.dataUser).then((res) => {
        // console.log(res);
        // console.log(res.code);

        if (res.code) {
          try {
            console.log(res?.message);

            arr.push(res?.message);
            console.log(arr);
            setTreeView(arr);
          } catch (error) {
            console.log(error);

          }
        } else {
          setTreeView([]);
        }
      });
    }
  }, [dataUser]);

  useEffect(() => {
    const countdown = () => {
      getData()
    };
    timerRef.current = setInterval(countdown, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);
  const getData = async () => {
    let data = await getInfoUserss();
    setDataUser(data)
    // console.log(data);
  }

  const handleCopy = (type) => {
    const input = document.createElement("input");
    input.style.opacity = 0;
    input.style.position = "absolute";
    input.value = `${window.location.origin}?ref=${state?.address_web3}`;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    // if (_data) {
    //     openNotic('success', "Pool has been canceled")
    // }
    alert('copied successfully')
  };

  // console.log(dataUser);
  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="container-lg ibgate-page p-0">
          <h1>
            Symtems Node
            <span
              className="copytext codeMyCopy"
            // onclick="copyText('#codeCopy', 'Copy success')"
            >
              <i className="far fa-copy"></i>
            </span>
          </h1>

          <div className="col col-12 p-0 mt-4" style={{ color: '#fff', fontSize: 13 }}>
            <div className="block-item item-ocean-pools">
              <div className="block-item__wrap">
                {/* <h2 className="block-item__title mb-4" style={{ fontSize: 18, color: '#fff' }}>Symtems Node</h2> */}
                <div className="row" style={{ overflow: 'auto', maxWidth: '100%' }}>
                  <div className="col-12" style={{ color: '#fff' }}>
                    {
                      treeView.length ? (
                        <TreeMenu
                          cacheSearch
                          data={treeView}
                          debounceTime={125}
                          disableKeyboard={false}
                          hasSearch={false}
                          // onClickItem={function noRefCheck(e) {
                          //   openTreeView(e);
                          // }}
                          resetOpenNodesOnDataUpdate={false}
                        />
                      ) : (
                        <ul className="rstm-tree-item-group">
                          <li
                            className="rstm-tree-item rstm-tree-item-level0"
                            role="button"
                            aria-pressed="false"
                            style={{ paddingLeft: "0.75rem" }}
                          >
                            None
                          </li>
                        </ul>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
