import HeaderV2 from 'components/Header/HeaderV2'
import SidebarV2 from 'components/Sidebar/SidebarV2'
import Layout from 'components/layout/Layout'
import Notification from 'components/lib/notification'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatNumber } from 'service'
import { claimTotal, getInfoLending, getInfoUserss, lending, withdraw } from 'service/indexNew'

function Lending() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const [sttMoldelAffiliate, setSttMoldelAffiliate] = useState(false);
	const [amount, setAmount] = useState(0);
	const [branch, setBranch] = useState("");
	const [userAddress, setUserAddress] = useState("");
	const timerRef = useRef(null);
	const [dataUser, setDataUser] = useState({});
	const [notificationObj, setNotificationObj] = useState({
		show: false,
		stt: true,
		msg: ""
	});

	useEffect(() => {
		const countdown = () => {
			getData()
		};
		timerRef.current = setInterval(countdown, 1000);
		return () => {
			clearInterval(timerRef.current);
		};
	}, []);

	// // new
	// useEffect(async () => {
	// 	let data = await getInfoUserss();
	// 	setDataUser(data)
	// 	// console.log(data);
	// 	setInterval(async () => {
	// 		let data = await getInfoUserss();
	// 		setDataUser(data)
	// 		// console.log(data);
	// 	}, 1000);
	// }, []);

	const getData = async () => {

		let data = await getInfoLending();
		// console.log(data);
		if (!data) {
			return
		}
		let obj = data.data;
		let times = Math.floor((Date.now() - obj?.created) / 1000);
		let profitearns = (obj?.amount * times * obj?.pt / 259200000) - obj?.profit;
		obj.profitearn = profitearns;
		setData(obj)

		let dataUser = await getInfoUserss();
		setDataUser(dataUser)
		// console.log(dataUser);

	}
	// //new
	// useEffect(async () => {
	// 	let data = await getInfoLending();
	// 	console.log(data);
	// 	if (obj) {

	// 	}
	// }, []);

	const _lending = async () => {
		setLoading(true)
		let res = await lending(amount);
		// console.log(res);
		if (res.code == 200) {
			if (res?.message) {
				openNotic('success', 'success')
			} else {
				openNotic('error', "fail")
			}
		} else {
			// console.log('----------------', res?.message);
			if (res?.message) {
				openNotic('error', res?.message)
			} else {
				openNotic('error', "fail")
			}
			// console.log("Signature not verified");
		}
		setLoading(false);
	}

	const openNotic = (stt, msg) => {
		setNotificationObj({ show: true, stt, msg })
		setTimeout(() => {
			setNotificationObj({ show: false, stt, msg })
		}, 5000);
	};


	const _withdraw = async () => {
		setLoading(true)
		let res = await withdraw(amount);
		// console.log(res);
		if (res.code == 200) {
			if (res?.message) {
				openNotic('success', 'success')
			} else {
				openNotic('error', "fail")
			}
		} else {
			console.log('----------------', res?.message);
			if (res?.message) {
				openNotic('error', res?.message)
			} else {
				openNotic('error', "fail")
			}
			// console.log("Signature not verified");
		}
		setLoading(false);
	}

	const _claimTotal = async () => {
		setLoading(true)
		let res = await claimTotal(amount);
		// console.log(res);
		if (res.code == 200) {
			if (res?.message) {
				openNotic('success', 'success')
			} else {
				openNotic('error', "fail")
			}
		} else {
			console.log('----------------', res?.message);
			if (res?.message) {
				openNotic('error', res?.message)
			} else {
				openNotic('error', "fail")
			}
			// console.log("Signature not verified");
		}
		setLoading(false);
	}
	
	// console.log(dataUser?.description?.unpaid_balanceOld?.[0]);
	return (
		<Layout>
			<div style={{ backgroundColor: '#0D102D' }} className="body customers_page flex-grow-1 px-3">
				<div className="body customers_page flex-grow-1 px-3">
					<div className="container-lg ibgate-page">

						<h1>Investment Bot</h1>
						<div class="boxLendinginfo">
							<p>Invest in bot trader and get 1% profit per day</p>
							
							<p>Min of invest 100 USDT</p>

						</div>
						<div className="row list-item lending_list">
							<div className="col-sm-6 col-lg-3 col-md-6">
								<div data-val="deposit" className="box nStbg  active ">
									<p className='mb-4'>Balance</p>
									<span>{formatNumber(dataUser?.balanceUsdt)} <b className="uppercase">USDT</b> </span>
								</div>
							</div>
							<div className="col-sm-6 col-lg-3 col-md-6">
								<div className="box nStbg ">
									<p className='mb-4'>Total</p>
									<span>{formatNumber((dataUser?.balanceLending * 1))} <b className="uppercase">USDT</b> </span>
									<a onClick={_claimTotal}>Withdraw</a>
								</div>
							</div>

							<div className="col-sm-6 col-lg-3 col-md-6">
								<div data-val="invest" className="box nStbg  ">
									<p>Investment Bot</p>
									<span>{formatNumber(data?.amount)} <b className="uppercase">USDT</b> </span>
									<div className="form_group mt-2">
										<input
											name="money"
											data-type="money"
											required
											min="50"
											data-exchange="25000"
											data-currency="USDT"
											data-min="50"
											className="getMoneyValdUsd form-control form-control-lg"
											type="text"
											value={amount}
											onChange={(e) => setAmount(e.target.value)}
											placeholder="Enter the amount"
										/>
									</div>
									<a onClick={_lending}>Investment</a>
								</div>
							</div>
							<div className="col-sm-6 col-lg-3 col-md-6">
								<div className="box nStbg ">
									<p>Interest ({data?.pt / 30}%/day)</p>

									<span><b id="getInterest" data-value="1.6318752519951" data-step="0.00048611111111111">{(data?.profitearn * 1).toFixed(6)}</b> <b
										className="uppercase">USDT</b> </span>

									<a onClick={_withdraw}>Withdraw</a>
								</div>
							</div>
							<div className="col-sm-6 col-lg-6 col-md-6">
								<div data-val="invest" className="box nStbg ">
									<p className="mb-4">Interest and Commissions</p>
									<span>{formatNumber((dataUser?.balanceLending * 1) + (dataUser?.withdrawBalanceLending * 1))} <b className="uppercase">USDT</b> </span>
								</div>
							</div>
							<div className="col-sm-6 col-lg-6 col-md-6">
								<div data-val="invest" className="box nStbg ">

									<p className="mb-4">Maxout 200%</p>
									<span>{formatNumber(data?.amount * 2)} <b className="uppercase">USDT</b> </span>
								</div>
							</div>

							{/* <div className="col-sm-6 col-lg-3 col-md-6">
								<div data-val="invest" className="box nStbg ">
									<p className="mb-4">Total left branch sales</p>
									<span>{formatNumber((dataUser?.description?.unpaid_balanceOld?.[0]))} <b className="uppercase">USDT</b> </span>

								</div>
							</div> */}

							{/* <div className="col-sm-6 col-lg-3 col-md-6">
								<div data-val="invest" className="box nStbg ">
									<p className="mb-4">Total right branch sales</p>
									<span>{formatNumber((dataUser?.description?.unpaid_balanceOld?.[1]))} <b className="uppercase">USDT</b> </span>

								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			{
				notificationObj?.show ?
					<Notification status={notificationObj?.stt} msg={notificationObj?.msg} />
					: null
			}
		</Layout>
	)
}

export default Lending
