

import React, { useEffect, useState } from 'react'
// import * as routeLink from "../../constants/routeLink";
import { useNavigate } from 'react-router-dom';
import { createChart } from 'lightweight-charts';
import { useSelector } from 'react-redux';
// import { bet, domain, getChartData, } from '../../services/services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bet, domains, getInfoUserss } from 'service/indexNew';
import Layout from "components/layout/Layout";
import Notification from "components/lib/notification";
import WaterDrop from 'components/lib/waterDrop';
let chart
let area
let listResult1 = [
  3, 3, 3, 3, 3,
  3, 3, 3, 3, 3,
  3, 3, 3, 3, 3,
  3, 3, 3, 3, 3
], listResult2 = [
  3, 3, 3, 3, 3,
  3, 3, 3, 3, 3,
  3, 3, 3, 3, 3,
  3, 3, 3, 3, 3
], listResult3 = [
  3, 3, 3, 3, 3,
  3, 3, 3, 3, 3,
  3, 3, 3, 3, 3,
  3, 3, 3, 3, 3
], totalBuyResult = 0, totalSellResult = 0;
const totResutShow = 60;
let countCall = 0;
// let idGame = 0;
let buyMy = 0;
let sellMy = 0;

const Index = (props) => {
  const [timeChart, setTimeChart] = useState('1m');
  const [priceDm3, setPriceDm3] = useState(0);
  const [user, setUser] = useState({});
  const [listResult, setListResult] = useState([]);

  const [notificationObj, setNotificationObj] = useState({
    show: false,
    stt: true,
    msg: ""
  });

  const { apiReducer } = useSelector(state => state);


  const [isScroll, setisScroll] = useState(false);

  // const { user } = apiReducer;

  // const [amountTrade, setamountTrade] = useState(user?.balancePoint>10?10:0);
  const [amount, setamount] = useState(0);

  const [timeLeft, setTimeLeft] = useState({});

  const openNotic = (stt, msg) => {
    // console.log({ show: true, stt, msg });
    setNotificationObj({ show: true, stt, msg })
    setTimeout(() => {
      setNotificationObj({ show: false, stt, msg })
    }, 5000);
  };

  useEffect(() => {

    const get = setInterval(async () => {
      let data = await getInfoUserss();
      setUser(data);

      // if (new Date().getUTCSeconds() < 3) {
      //   const res = await getInfoBet();
      //   if (res.code == 200) {
      //     idGame = res.data.idGame;
      //     setinfobet(res.data);
      //     window.localStorage.setItem('idGame', idGame)
      //   }
      //   sellMy = 0;
      //   buyMy = 0;
      // }


      let idGame = window.localStorage.getItem('idGame');
      let datase = JSON.parse(window.localStorage.getItem('betGame'));
      if (datase) {
        if (datase[idGame]) {
          if (datase[idGame].buy) {
            buyMy = datase[idGame].buy;
          }
          if (datase[idGame].sell) {
            sellMy = datase[idGame].sell;
          }
        } else {
          sellMy = 0;
          buyMy = 0;
        }
      } else {
        sellMy = 0;
        buyMy = 0;
      }

    }, 1000);

    return () => {
      clearInterval(get);
    };
  }, []);

  const setamountTrade_ = (e) => {
    console.log(e);
    console.log(user);

    if (e != "all") {
      if (e <= (user?.balanceUsdt)) {
        setamount(e);
      } else {
        setamount((user?.balanceUsdt));
        // alert("Insufficient balance");
      }

    } else {
      setamount((user?.balanceUsdt));
    }
  }

  const [countdown, setCountdown] = useState(60);
  const [isEvenMinute, setIsEvenMinute] = useState(true);

  useEffect(() => {
    const calculateCountdown = () => {
      const now = new Date();
      const currentMinute = now.getMinutes();
      const currentSecond = now.getSeconds();
      let secondsUntilNextMinute;

      if (currentMinute % 2 === 0) {
        // Nếu phút hiện tại là chẵn, đếm ngược tới phút lẻ tiếp theo
        secondsUntilNextMinute = 60 - currentSecond;
        setIsEvenMinute(true);
      } else {
        // Nếu phút hiện tại là lẻ, đếm ngược tới phút chẵn tiếp theo
        secondsUntilNextMinute = 60 - currentSecond;
        setIsEvenMinute(false);
      }

      setCountdown(secondsUntilNextMinute);
    };

    calculateCountdown();

    const interval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          calculateCountdown();
          return 60; // Khởi động lại quá trình đếm ngược
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    startChart()
    return () => {

    };
  }, []);

  const handingResutlShow = (dt) => {
    const dataGt = new Date();
    const minu = dataGt.getUTCMinutes();
    if (dt.length < totResutShow) {
      return
    }
    let arrC = [];
    for (let index = 0; index < minu; index++) {
      const element = dt[index + (totResutShow - minu)];
      arrC.push(element)
    }

    let arr1 = [], arr2 = [], arr3 = [], ttb = 0, tts = 0;

    for (let index = 0; index < totResutShow; index++) {
      const element = arrC[index];
      let amountSet = 3;
      if (index <= minu - 1) {
        if (Number(element[1]) >= Number(element[4])) {
          amountSet = 2;
          ttb++;
        } else {
          amountSet = 1;
          tts++;
        }
      }

      if (index < 20) {
        arr1.push(amountSet);
      } else if (index < 40) {
        arr2.push(amountSet);
      } else {
        arr3.push(amountSet);
      }
      listResult1 = arr1;
      listResult2 = arr2;
      listResult3 = arr3;
      totalBuyResult = ttb;
      totalSellResult = tts;
    }
  }

  const startChart = () => {
    console.log(window.screen.width, window.screen.height);

    let wscren = 0, isScreen = window.screen.width;
    let h = window.screen.height - 420;
    if (isScreen < 450) {
      wscren = isScreen

    } else if (isScreen > 1000) {
      wscren = 978
      // h = 280;
      // h = window.screen.height - 520;
    } else {
      wscren = isScreen - 100
    }
    if (window.screen.height > 1000) {
      // wscren = isScreen
      h = 600
    }
    // alert(isScreen)

    const chartProperties = {
      width: wscren,
      height: h,
      timeScale: {
        timeVisible: true,
        secondsVisible: true,
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.3,
          bottom: 0.25,
        },
        borderVisible: false,
      },

      layout: {
        background: {
          color: '#000000'
        },
        textColor: '#ffffff',
      },
      grid: {
        vertLines: {
          color: 'rgba(42, 46, 57, 0.6)',
        },
        horzLines: {
          color: 'rgba(42, 46, 57, 0.6)',
        },
      },
    }
    const domElement = document.getElementById('tvchart');
    chart = createChart(domElement, chartProperties);

    area = chart.addCandlestickSeries();

    area.applyOptions({
      priceFormat: {
        type: 'price',
        precision: 6,
        minMove: 0.000001,
      }
    });

    setInterval(() => {
      let timeChar = timeChart;
      fetch(`${domains}/chart/klines?limit=200`)
        .then(res => res.json())
        .then(data => {
          data = data.data;
          if (!data) {
            data = []
          }
          if (data.length > (totResutShow + 3)) {
            handingResutlShow(data.slice(-(totResutShow + 1)))
          }

          let rate = 1;
          let closeSrast = 0.0731;
          const cdata = data.map(d => {
            if (d[0] > 1646380623000) {
              return { time: (d[0] + 25200000) / 1000, open: parseFloat(d[1]) / rate, high: parseFloat(d[2]) / rate, low: parseFloat(d[3]) / rate, close: parseFloat(d[4]) / rate }
            } else {
              return { time: (d[0] + 25200000) / 1000, open: closeSrast / rate, high: closeSrast / rate, low: closeSrast / rate, close: closeSrast / rate }
            }
          });
          area.setData(cdata);
        })
        .catch(err => console.log(err))
    }, 1000);
    chart.timeScale().scrollToPosition(200, true);
  }

  // let wscren = 0, isScreen = window.screen.width;

  // if (isScreen < 450) {
  //   wscren = isScreen - 30
  // } else if (isScreen > 1500) {
  //   wscren = 1448
  // } else {
  //   wscren = isScreen - 200
  // }

  const bet_ = async (type) => {
    if (amount > 0) {
      console.log(amount, type);

      const res = await bet(amount, type);
      // console.log(res);
      // toast(res.message);
      if (res?.message) {
        openNotic('success', res.message);
        // window.localStorage.getItem('betGame');

        let datase = JSON.parse(window.localStorage.getItem('betGame'));
        let buy = 0, sell = 0;
        let obj = {};
        obj[res.data.idGame] = {};
        if (datase) {
          if (!datase[res.data.idGame]) {
            obj[res.data.idGame] = {};
          } else {
            if (datase[res.data.idGame].buy) {
              buy = datase[res.data.idGame].buy;
              obj[res.data.idGame].buy = buy;
            }
            if (datase[res.data.idGame].sell) {
              sell = datase[res.data.idGame].sell;
              obj[res.data.idGame].sell = sell;
            }
          }
        }

        if (type == 'buy') {
          obj[res.data.idGame]['buy'] = buy * 1 + amount;
        } else {
          obj[res.data.idGame]['sell'] = sell * 1 + amount;
        }

        window.localStorage.setItem(`betGame`, JSON.stringify(obj));
        setamountTrade_(0);
      }
    }

  }
  // console.log(listResult23);

  // const dataGiotNuoc1 = [
  //   1, 2, 1, 1, 2, 2, 2, 2,
  //   1, 1, 2, 1, 1, 2, 2, 1,
  //   2, 2, 2, 1
  // ];
  // const dataGiotNuoc2 = [
  //   1, 2, 1, 1, 2, 2, 2, 2,
  //   1, 1, 2, 1, 1, 2, 2, 1,
  //   2, 2, 2, 1
  // ];
  // const dataGiotNuoc3 = [
  //   1, 2, 1, 1, 2, 2, 2, 2,
  //   1, 1, 2, 1, 1, 2, 2, 3,
  //   3, 3, 3, 3
  // ];

  return (
    <Layout>
      <div style={{ background: '#000' }}>

      <div style={{ height: 20 }}></div>

        <section>

          <div className="show-nav clcllclcaosodawdasdsa">
            {/* <div className="hidden-mobile">
              <div className="navbar-menu-desktop bgSecondary3">

                <div className="navbar d-flex flex-column justify-content-between align-items-center p-3 h-100">
                </div>
              </div>
            </div> */}
            <div className="transition-all-01 ">

              <div className="main main-chart position-relative">
                <div className="info-chart-top position-absolute d-flex justify-content-center align-items-center zIndex-4">
                  <div className="wf h-100 me-2">
                    <button className="button second-normal p-0 px-2 py-1 h-100">
                      <svg data-v-37f15067="" height="20px" width="20px" version="1.1" id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 365.892 365.892" xmlSpace="preserve">
                        <g data-v-37f15067="">
                          <circle data-v-37f15067="" id="network-bad" cx="182.945" cy="286.681" r="41.494" fill="#04c793"
                            style={{ transition: "0.5s;" }}></circle>
                          <path data-v-37f15067="" id="network-slow" d="M182.946,176.029c-35.658,0-69.337,17.345-90.09,46.398c-5.921,8.288-4.001,19.806,4.286,25.726
            c3.249,2.321,6.994,3.438,10.704,3.438c5.754,0,11.423-2.686,15.021-7.724c13.846-19.383,36.305-30.954,60.078-30.954
            c23.775,0,46.233,11.571,60.077,30.953c5.919,8.286,17.437,10.209,25.726,4.288c8.288-5.92,10.208-17.438,4.288-25.726
            C252.285,193.373,218.606,176.029,182.946,176.029z" fill="#04c793" style={{ transition: "0.5s;" }}></path>
                          <path data-v-37f15067="" id="network-good" d="M182.946,106.873c-50.938,0-99.694,21.749-133.77,59.67c-6.807,7.576-6.185,19.236,1.392,26.044
            c3.523,3.166,7.929,4.725,12.32,4.725c5.051-0.001,10.082-2.063,13.723-6.116c27.091-30.148,65.849-47.439,106.336-47.439
            s79.246,17.291,106.338,47.438c6.808,7.576,18.468,8.198,26.043,1.391c7.576-6.808,8.198-18.468,1.391-26.043
            C282.641,128.621,233.883,106.873,182.946,106.873z" fill="#04c793" style={{ transition: "0.5s;" }}></path>
                          <path data-v-37f15067="" id="network-excellent" d="M360.611,112.293c-47.209-48.092-110.305-74.577-177.665-74.577c-67.357,0-130.453,26.485-177.664,74.579
            c-7.135,7.269-7.027,18.944,0.241,26.079c3.59,3.524,8.255,5.282,12.918,5.281c4.776,0,9.551-1.845,13.161-5.522
            c40.22-40.971,93.968-63.534,151.344-63.534c57.379,0,111.127,22.563,151.343,63.532c7.136,7.269,18.812,7.376,26.08,0.242
            C367.637,131.238,367.745,119.562,360.611,112.293z" fill="#04c793" style={{ transition: "0.5s;" }}></path>
                        </g>
                      </svg>
                    </button>
                  </div>

                  <div className="coint-info h-100 "> <button className="button second-normal p-0  px-1 py-1 h-100"> <span
                    className="text-white fw-bold">BTC / USDT</span> </button></div>

                </div>

                <div className="tradingview-widget-container h-100">
                  <div className='' id="tvchart"></div>
                </div>
              </div>
            </div>

            <div className="kskkksdkawds">

              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                <div style={{ color: '#fff' }}>
                  Last results
                </div>
                <div className="overviewInfo d-flex align-items-center my-auto" style={{ color: "#fff" }}>
                  <div className="badgeItem">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25.413" height="12.844" viewBox="0 0 25.413 12.844">
                      <g id="trend-up" transform="translate(4.658 -8)">
                        <path
                          id="Path_26233"
                          data-name="Path 26233"
                          d="M25.755,8H16.122l4.249,4.249-6.623,6.623L7.893,12.283a.8.8,0,0,0-1.129-.071L.342,17.831,1.4,19.04l5.822-5.095,5.893,6.629a.806.806,0,0,0,.576.27h.023a.8.8,0,0,0,.568-.235l7.225-7.225,4.249,4.249Z"
                          transform="translate(-5)"
                          fill="#01B58C"
                        />
                      </g>
                    </svg>
                    <span className="ml-1 ml-lg-3">{totalBuyResult}</span>
                  </div>
                  <div className="badgeItem ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.587" height="11.921" viewBox="0 0 23.587 11.921">
                      <g id="trend-down" transform="translate(-0.342 -8)">
                        <path
                          id="Path_26234"
                          data-name="Path 26234"
                          d="M23.929,19.921H14.988l3.944-3.944L12.785,9.831,7.35,15.945a.744.744,0,0,1-1.048.066L.342,10.8l.981-1.122,5.4,4.729L12.2,8.25A.747.747,0,0,1,12.731,8h.022a.743.743,0,0,1,.527.218l6.705,6.705,3.944-3.944Z"
                          fill="#FC5F5F"
                        />
                      </g>
                    </svg>
                    <span className="ml-1 ml-lg-3">{totalSellResult}</span>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <WaterDrop data={listResult1} />
                <WaterDrop data={listResult2} />
                <WaterDrop data={listResult3} />
              </div>
            </div>

            <div
              className="bottom-mobile-trade d-flex flex-column justify-content-center align-items-center  hidden-desktop p-3  card-custom borderSecondary2 bgSecondary4">
              <div className=" grid-amount-2 w-100 mb-2">
                <div>
                  <button class={`button second-normal btnMainBorder btn-amount  w-100 h-100 p-0 ${amount == 5 ? "active" : ""}`} onClick={() => setamountTrade_(5)}>5</button>
                </div>
                <div>
                  <button class={`button second-normal btnMainBorder btn-amount  w-100 h-100 p-0 ${amount == 10 ? "active" : ""}`} onClick={() => setamountTrade_(10)}>10</button>
                </div>
                <div>
                  <button class={`button second-normal btnMainBorder btn-amount  w-100 h-100 p-0 ${amount == 20 ? "active" : ""}`} onClick={() => setamountTrade_(20)}>20</button>
                </div>
                <div>
                  <button class={`button second-normal btnMainBorder btn-amount  w-100 h-100 p-0 ${amount == 50 ? "active" : ""}`} onClick={() => setamountTrade_(50)}>50</button>
                </div>
                <div>
                  <button class={`button second-normal btnMainBorder btn-amount  w-100 h-100 p-0 ${amount == 100 ? "active" : ""}`} onClick={() => setamountTrade_(100)}>100</button>
                </div>
                <div>
                  <button class={`button second-normal btnMainBorder btn-amount  w-100 h-100 p-0 ${amount == "all" ? "active" : ""}`} onClick={() => setamountTrade_("all")}>All</button>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center  w-100 mb-2 trader ">
                <div className="d-flex justify-content-center align-items-center  h-100 row w-100">
                  <div className="h-100 col-2 p-0 d-flex justify-content-center align-items-center pe-1">
                    <button className="button second-normal btnMainBorder  h-100   w-100 " onClick={() => {

                      let tt = amount - 1;
                      if (tt < 5) {
                        tt = 5;
                      }
                      setamountTrade_(tt)

                    }}><span className=" w-100 d-flex justify-content-center align-items-center ">-</span></button>
                  </div>
                  <div className="h-100  col-8 p-0 d-flex justify-content-center align-items-center pe-1">
                    <input type='number' className="h-100   w-100" value={amount} onChange={(e) => {

                      setamountTrade_(e.target.value)
                    }} />
                  </div>
                  <div className="h-100  col-2 p-0 d-flex justify-content-center align-items-center ">
                    <button className="button second-normal btnMainBorder  h-100   w-100 " onClick={() => {

                      let tt = amount + 1;
                      if (tt > user.balanceUsdt) {
                        tt = user.balanceUsdt;
                      }
                      setamountTrade_(tt)

                    }}><span className=" w-100 d-flex justify-content-center align-items-center " > +</span></button>
                  </div>
                </div>
              </div>

              <div className="d-flex  justify-content-between align-items-center  row w-100 trader-action">
                <div className="d-flex justify-content-start align-items-center  h-100  flex-column col-4  p-0 pe-1 ">
                  <button className="button  second btnMainBorder w-100 h-100 nav-grid-2 bg-btn-color-main" onClick={() => bet_("buy")} disabled={!isEvenMinute}>
                    <div className="d-flex justify-content-start align-items-start">
                      {/* <span className="ms-2 fs-4 text-white">Buy</span> */}
                      <span className="ms-2 text-white" style={{ fontSize: 18 }}>Buy <div style={{ fontSize: 11 }}>({buyMy})</div></span>
                    </div>
                    <div><svg data-v-76861cc0="" xmlns="http://www.w3.org/2000/svg" width="23.087" height="11.668"
                      viewBox="0 0 23.087 11.668" className="ml-1">
                      <path data-v-76861cc0="" id="Path_26233" data-name="Path 26233"
                        d="M23.429,8H14.678l3.86,3.86-6.016,6.016L7.2,11.891a.728.728,0,0,0-1.025-.065l-5.834,5.1.96,1.1L6.592,13.4l5.353,6.022a.732.732,0,0,0,.524.245h.021a.727.727,0,0,0,.516-.214l6.563-6.563,3.86,3.86Z"
                        transform="translate(-0.342 -8)" fill="#fff"></path>
                    </svg></div>

                  </button>
                </div>
                <div className="d-flex justify-content-center h-100 align-items-center flex-column col-4  p-0 pe-1">
                  <button className="button second-normal  btnMainBorder w-100 py-3 h-100 bg-btn-color-main">
                    <div className="d-flex justify-content-center align-items-center h-100 w-100 ">
                      <span className="ms-2  text-white">
                        {/* {isEvenMinute && "Hãy đặt lệnh"} <br></br> */}
                        {"Time"} <br></br>

                        <span className='fs-4'> {countdown}</span>
                      </span>
                    </div>

                  </button>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column  h-100  col-4 p-0 ">
                  <button className="button  bg-danger btnMainBorder h-100 w-100 nav-grid-2 " onClick={() => bet_("sell")} disabled={!isEvenMinute}>
                    <div className="d-flex justify-content-start align-items-start">
                      <span className="ms-2 text-white" style={{ fontSize: 18 }}>Sell <div style={{ fontSize: 11 }}>({sellMy})</div></span>
                    </div>
                    <div><svg data-v-76861cc0="" xmlns="http://www.w3.org/2000/svg" width="23.587" height="11.921"
                      viewBox="0 0 23.587 11.921" className="ml-1">
                      <g data-v-76861cc0="" id="trend-down" transform="translate(-0.342 -8)">
                        <path data-v-76861cc0="" id="Path_26234" data-name="Path 26234"
                          d="M23.929,19.921H14.988l3.944-3.944L12.785,9.831,7.35,15.945a.744.744,0,0,1-1.048.066L.342,10.8l.981-1.122,5.4,4.729L12.2,8.25A.747.747,0,0,1,12.731,8h.022a.743.743,0,0,1,.527.218l6.705,6.705,3.944-3.944Z"
                          fill="#fff"></path>
                      </g>
                    </svg> </div>

                  </button>
                </div>
              </div>
            </div>

            {/* <div className="kskkksdkawds">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <WaterDrop data={dataGiotNuoc1} />
                <WaterDrop data={dataGiotNuoc2} />
                <WaterDrop data={dataGiotNuoc3} />
              </div> */}
            {/* <svg width="50" height="50">
                <circle cx="25" cy="25" r="12.5" fill="#2f3342" />
              </svg>
              {
                listResult23.map(e => {
                  return (

                    e ?
                      <svg width="50" height="50">
                        < circle cx="25" cy="25" r="12.5" fill="#dc3545" />
                      </svg>
                      :

                      <svg width="50" height="50">
                        <circle cx="25" cy="25" r="12.5" fill="#18c07c" />
                      </svg>

                  )
                })
              } */}
            {/* <p>{listResult23}</p> */}
            {/* <svg width="50" height="50">
                <circle cx="25" cy="25" r="12.5" fill="#18c07c" />
              </svg>
              <svg width="50" height="50">
                <circle cx="25" cy="25" r="12.5" fill="#dc3545" />
              </svg>
              <svg width="50" height="50">
                <circle cx="25" cy="25" r="12.5" fill="#18c07c" />
              </svg>
              <svg width="50" height="50">
                <circle cx="25" cy="25" r="12.5" fill="#dc3545" />
              </svg>
              <svg width="50" height="50">
                <circle cx="25" cy="25" r="12.5" fill="#18c07c" />
              </svg>
              <svg width="50" height="50">
                <circle cx="25" cy="25" r="12.5" fill="#dc3545" />
              </svg>
              <svg width="50" height="50">
                <circle cx="25" cy="25" r="12.5" fill="#18c07c" />
              </svg>
              <svg width="50" height="50">
                <circle cx="25" cy="25" r="12.5" fill="#dc3545" />
              </svg>
              <svg width="50" height="50">
                <circle cx="25" cy="25" r="12.5" fill="#2f3342" />
              </svg> */}
            {/* </div> */}
          </div>
        </section>
        <div style={{ height: 20 }}></div>
      </div >
      {
        notificationObj?.show ?
          <Notification status={notificationObj?.stt} msg={notificationObj?.msg} />
          : null
      }
    </Layout >
  )
}

export default Index;
