import React, { useState } from 'react';
import LoadingButton from "components/lib/loading";
import { updateAffiliate } from "service"

const UpdateAffiliateProgram = ({ changeMDWLST, refs, branch, onChangeRef, onChangeBranch, fcSubmit, pass2, onChangePass2 }) => {
    // const [rewardPercentage, setRewardPercentage] = useState(percent || '');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        // setLoading(true);
        fcSubmit()
    };
    // console.log(rewardPercentage);

    return (
        <div className="ant-modal-root">
            <div className="ant-modal-mask"></div>
            <div className="ant-modal-wrap" tabIndex="-1" role="dialog" aria-labelledby="rcDialogTitle0">
                <div role="document" className="ant-modal" style={{ width: '520px', transformOrigin: '686px 516px' }}>
                    <div tabIndex="0" aria-hidden="true" style={{ width: '0px', height: '0px', overflow: 'hidden', outline: 'none' }}></div>
                    <div className="ant-modal-content">
                        <button onClick={() => { changeMDWLST(false) }} type="button" aria-label="Close" className="ant-modal-close">
                            <span className="ant-modal-close-x">
                                <span role="img" aria-label="close" className="anticon anticon-close ant-modal-close-icon">
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                        <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                    </svg>
                                </span>
                            </span>
                        </button>
                        <div className="ant-modal-header">
                            <div className="ant-modal-title" id="rcDialogTitle0">Notification</div>
                        </div>
                        <div className="ant-modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="field">
                                    <label className="label" htmlFor="ref">Ref By</label>
                                    <div className="control">
                                        <input
                                            className={`input`}
                                            placeholder="Ex: 0x000000000000"
                                            id="ref"
                                            name="ref"
                                            value={refs}
                                            onChange={(e) => onChangeRef(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/* <div className="field">
                                    <label className="label" htmlFor="branch">Branch (Left: 0 - Right: 1)</label>
                                    <div className="control">
                                        <input
                                            className={`input`}
                                            type={"number"}
                                            placeholder="Ex: 0"
                                            id="branch"
                                            name="branch"
                                            value={branch}
                                            onChange={(e) => onChangeBranch(e.target.value)}
                                        />
                                    </div>
                                </div> */}

                                <div className="field">
                                    <label className="label" htmlFor="branch">Password Level 2</label>
                                    <div className="control">
                                        <input
                                            className={`input`}
                                            type="password"
                                            placeholder="Ex: abcdRs123"
                                            id="branch"
                                            name="branch"
                                            value={pass2}
                                            onChange={(e) => onChangePass2(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="has-text-centered">

                                    {
                                        loading ?
                                            <LoadingButton type="button" className="ant-btn ant-btn-primary" />
                                            :
                                            <button type="submit" className={`ant-btn ant-btn-primary`}>
                                                <span>Confirm</span>
                                            </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                    <div tabIndex="0" aria-hidden="true" style={{ width: '0px', height: '0px', overflow: 'hidden', outline: 'none' }}></div>
                </div>
            </div>
        </div>
    );
};

export default UpdateAffiliateProgram;
