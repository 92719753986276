import bigDecimal from "js-big-decimal";
// const BigNumber = require('bignumber.js');

import Web3 from "web3";
import * as bscContracts from "./contractBsc";
import * as bscTestContracts from "./contractBscTest";
import * as ethContracts from "./contractEth";
import * as arbContracts from "./contractArb";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { useGlobalVar } from "./globalVar";

const feeCreateLaunchpad = 1 * 1e18;
const feeCreateToken = 0.4 * 1e18;

let web3Prov, domain, bigUnit, feeDenominator, addresAntibot, addresBABYTOKENDividendTracker, contractUsdt, abi_usdt, contractStaking,
	abi_staking, contractTokenInfo, abi_tokenInfo, contractLaunchpad, abi_launchpad, contractFairlaunch, abi_fairlaunch,
	abi_childLaunchpad, abi_childStake, contractListLaunch, abi_listlaunch, abi_tokenDefault, contractLock, abi_lock,
	contractCreateStandardToken, abi_createStandardToken, contractCreateLiquidityGeneratorToken, abi_createLiquidityGeneratorToken,
	contractCreateAntiBotLiquidityGeneratorToken, abi_createAntiBotLiquidityGeneratorToken, contractCreateBABYTOKEN, abi_createBABYTOKEN,
	contractCreateAntiBotBABYTOKEN, abi_createAntiBotBABYTOKEN, contractCreateBuybackBabyToken, abi_createBuybackBabyToken,
	contractSender, abi_sender, contractCreateBankStaking, abi_bankstaking, contractListStake, abi_liststake, contractListBuy,
	abi_listbuy, contractListDepositStake, abi_listdepositstake, allVarChain, contractLockPink;

let contractsAllChain, provider_;

// import {
// 	contractTokenInfo, abi_tokenInfo
// } from "./contract";



const initProvider = async () => {

	if (provider_) {
		return provider_;
	}
	const url = window.location.href;
	const lastSlashIndex = url.lastIndexOf("/");
	const addressWithRefId = url.substring(lastSlashIndex + 1);
	const [refId, queryString] = addressWithRefId.split("?");
	const params = new URLSearchParams(queryString);
	const chianName = params.get("chain");
	// console.log(chianName);

	let chains, optionalChains;
	switch (chianName) {
		case "ARB":
			chains = [42161];
			optionalChains = [42161, 56, 1];
			break;
		case "ETH":
			chains = [1];
			optionalChains = [1, 42161, 56];
			break;
		case "BSC-Test":
			chains = [97];
			optionalChains = [97, 1, 42161, 56];
			break;
		default:
			chains = [56];
			optionalChains = [56, 1, 42161];
			break;
	}

	const provider = await EthereumProvider.init({
		projectId: "564c993e5bf943e846da81d17b9c1f5f", // REQUIRED your projectId
		chains, // REQUIRED chain ids
		optionalChains, // OPTIONAL chains
		showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal
		qrModalOptions: {
			themeVariables: {
				'--w3m-z-index': "9999",
			}
		}
	})

	await provider.enable();

	const result = await provider.request({ method: 'eth_requestAccounts' })
	// console.log(result);
	if (isValidAddress(result[0])) {
		provider_ = provider
	}
	return provider;

};

const setContract = async (chianId) => {
	switch (chianId) {
		case 1:
			contractsAllChain = ethContracts;
			break;

		case 97:
			contractsAllChain = bscTestContracts;
			break;

		case 42161:
			contractsAllChain = arbContracts;
			break;

		default:
			contractsAllChain = bscContracts;
			break;
	}
	({
		web3Prov, domain, bigUnit, feeDenominator, addresAntibot, addresBABYTOKENDividendTracker, contractUsdt, abi_usdt, contractStaking,
		abi_staking, contractTokenInfo, abi_tokenInfo, contractLaunchpad, abi_launchpad, contractFairlaunch, abi_fairlaunch,
		abi_childLaunchpad, abi_childStake, contractListLaunch, abi_listlaunch, abi_tokenDefault, contractLock, abi_lock,
		contractCreateStandardToken, abi_createStandardToken, contractCreateLiquidityGeneratorToken, abi_createLiquidityGeneratorToken,
		contractCreateAntiBotLiquidityGeneratorToken, abi_createAntiBotLiquidityGeneratorToken, contractCreateBABYTOKEN, abi_createBABYTOKEN,
		contractCreateAntiBotBABYTOKEN, abi_createAntiBotBABYTOKEN, contractCreateBuybackBabyToken, abi_createBuybackBabyToken,
		contractSender, abi_sender, contractCreateBankStaking, abi_bankstaking, contractListStake, abi_liststake, contractListBuy,
		abi_listbuy, contractListDepositStake, abi_listdepositstake, allVarChain, contractLockPink
	} = contractsAllChain);

}

const getAccountConnect = async () => {
	const typeConnect = window.localStorage.getItem("providerType");
	switch (typeConnect) {
		case "wallet":

			break;

		default:
			break;
	}
}


const getWeb3jss = async () => {
	const url = window.location.href;
	const lastSlashIndex = url.lastIndexOf("/");
	const addressWithRefId = url.substring(lastSlashIndex + 1);
	const [refId, queryString] = addressWithRefId.split("?");
	const params = new URLSearchParams(queryString);
	const chianName = params.get("chain");
	let rpcChain, newWeb3, chainId, chainIdWallet;

	if (Web3.givenProvider) {
		const msms = new Web3(Web3.givenProvider);
		chainIdWallet = await msms.eth.net.getId()
	}
	// console.log("chainIdWallet");
	// if (refId) {
	switch (chianName) {
		case 'ARB':
			chainId = 42161;
			rpcChain = "https://arb1.arbitrum.io/rpc";
			break;
		case 'ETH':
			chainId = 1;
			rpcChain = "https://mainnet.infura.io/v3/";
			break;
		case 'BSC-Test':
			chainId = 97;
			rpcChain = "https://data-seed-prebsc-1-s1.binance.org:8545/";
			break;
		case 'BSC':
			chainId = 56;
			rpcChain = "https://bsc-dataseed1.binance.org/";
			break;
		default:
			console.log("chainIdWallet");
			chainId = chainIdWallet;
			rpcChain = Web3.givenProvider;
			break;
	}
	// }
	// console.log(Web3.givenProvider , chainIdWallet , chainId);
	if (Web3.givenProvider && chainIdWallet == chainId) {
		// console.log('okeee');
		newWeb3 = new Web3(Web3.givenProvider);
	} else {
		// console.log('okeee111');
		newWeb3 = new Web3(rpcChain);
	}
	// console.log(newWeb3);
	setContract(chainId);
	// return newWeb3;
	// if (window.localStorage.getItem("slsll")) {
	// 	console.log(window.localStorage.getItem("slsll"));
	// 	if (JSON.parse(window.localStorage.getItem("slsll")).slsll) {
	// 		console.log(JSON.parse(window.localStorage.getItem("slsll")));
	// 		return JSON.parse(window.localStorage.getItem("slsll")).slsll
	// 	}
	// 	return newWeb3;
	// }

	// window.localStorage.setItem("provider", provider);
	// window.localStorage.setItem("providerType", "wallet")
	if (window.localStorage.getItem("providerType") == "wallet") {
		// console.log("wallet");
		let initProviderData = await initProvider();

		// const result = await initProviderData.request({ method: 'eth_requestAccounts' })
		// console.log(result);

		if (initProviderData) {
			// console.log(initProviderData);
			return new Web3(initProviderData)
		} else {
			return newWeb3;
		}
	}
	// console.log("metamask");
	return newWeb3;

}


const getWeb3Provider = async (providers) => {
	return await getWeb3jss();
}

const addTree = async function (ref) {

	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	const contract = new web3.eth.Contract(
		abi_staking,
		contractStaking
	);
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	// console.log(ref);
	// return
	return await contract.methods
		.addTree(ref)
		.send({ from: accounts[0] })
		.catch((err) => {
			return false;
		});
};


const getInfoUser = async function () {

	// const web3 = new Web3(Web3.givenProvider);
	// console.log("getInfoUser");
	const web3 = await getWeb3Provider(Web3.givenProvider);
	// console.log("web3", web3);
	let accounts = await web3.eth.getAccounts();
	// console.log("web3", accounts);
	// const contract = new web3.eth.Contract(
	//   abi_staking,
	//   contractStaking
	// );
	// console.log(accounts);
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	const balance = await web3.eth.getBalance(accounts[0]);
	const bnbBalance = web3.utils.fromWei(balance, 'ether');

	return {
		balanceBNB: bnbBalance
	};
};

const getRefUser = async function () {

	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	const contract = new web3.eth.Contract(
		abi_staking,
		contractStaking
	);
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	return await contract.methods._ref(accounts[0]).call();
};

const staking = async function (amount) {
	// return
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	const contract = new web3.eth.Contract(
		abi_staking,
		contractStaking
	);
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}

	return await contract.methods
		.staking(new bigDecimal(amount).multiply(bigUnit).value)
		.send({ from: accounts[0] })
		.catch((err) => {
			return false;
		});
};

const getInfoStaking = async function () {

	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	const contract = new web3.eth.Contract(
		abi_staking,
		contractStaking
	);
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	return await contract.methods._stake(accounts[0]).call();
};

const withdraw = async function () {
	// console.log(num);
	// return
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	const contract = new web3.eth.Contract(
		abi_staking,
		contractStaking
	);
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	return await contract.methods
		.withdraw()
		.send({ from: accounts[0] })
		.catch((err) => {
			return false;
		});
};

const unStaking = async function () {
	// console.log(num);
	// return
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	const contract = new web3.eth.Contract(
		abi_staking,
		contractStaking
	);
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	return await contract.methods
		.unStaking()
		.send({ from: accounts[0] })
		.catch((err) => {
			return false;
		});
};



const checkApproveStaking = async () => {
	// connect acount
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	const contract = new web3.eth.Contract(
		abi_usdt,
		contractUsdt
	);
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	// console.log(contract.methods);
	// return;
	let amountToken, amountApprove;
	let getAmountToken = await contract.methods.balanceOf(accounts[0]).call();
	let getAmountApprove = await contract.methods
		.allowance(accounts[0], contractStaking)
		.call();
	amountToken = Number(new bigDecimal(getAmountToken).divide(bigUnit).value);
	amountApprove = Number(
		new bigDecimal(getAmountApprove).divide(bigUnit).value
	);
	// console.log(amountToken, amountApprove);
	// console.log(amountToken <= amountApprove);
	if (amountToken <= amountApprove) {
		return { stt: true, balance: amountToken };
	} else {
		return { stt: false, balance: amountToken };
	}
};

const submitApproveStaking = async () => {
	// return
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	const contract = new web3.eth.Contract(
		abi_usdt,
		contractUsdt
	);
	let amountToken;
	let getAmountToken = await contract.methods.balanceOf(accounts[0]).call();

	amountToken = Number(new bigDecimal(getAmountToken).divide(bigUnit).value);
	var dataAmount = new bigDecimal(amountToken).multiply(bigUnit).value;
	return await contract.methods
		.approve(contractStaking, dataAmount)
		.send({ from: accounts[0] })
		.catch((err) => {
			return false;
		});
};

const getInfoTokenLaunchpad = async (contractAddress, msgKRK) => {
	// console.log(contractAddress, msgKRK);
	// return
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	if (!contractAddress) {
		return
	}

	const contract = new web3.eth.Contract(
		abi_tokenDefault,
		contractAddress
	);
	// console.log('here', msgKRK);
	if (msgKRK == "notBuy") {
		msgKRK = contractLaunchpad;
	} else if (msgKRK == "fairlaunch") {
		msgKRK = contractFairlaunch;
	} else if (msgKRK == "lock") {
		msgKRK = contractLock;
	} else if (msgKRK == "sender") {
		msgKRK = contractSender;
	} else if (msgKRK == "stake") {
		msgKRK = contractCreateBankStaking;
	}
	// console.log('here1', msgKRK);
	// console.log(contractAddress, msgKRK);

	let [
		name,
		symbol,
		decimals,
		balance,
		allowance,
		totalSupply,
		burnedAmount
	] = await Promise.all([
		contract.methods.name().call(),
		contract.methods.symbol().call(),
		contract.methods.decimals().call(),
		contract.methods.balanceOf(accounts[0]).call(),
		contract.methods.allowance(accounts[0], msgKRK).call(),
		contract.methods.totalSupply().call(),
		contract.methods.balanceOf("0x000000000000000000000000000000000000dead").call()
	]);


	return ({
		allowance: allowance / (10 ** decimals),
		balance: balance / (10 ** decimals),
		burnedAmount: burnedAmount / (10 ** decimals),
		decimals,
		name,
		symbol,
		totalSupply: totalSupply / (10 ** decimals)
	})
	// return await contract.methods.getTokenInfo(contractAddress, accounts[0], contractLaunchpad).call();
};

const getInfoToken = async (contractAddress, msgKRK) => {
	// console.log(contractAddress, msgKRK);
	// return
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	if (!contractAddress) {
		return
	}

	const contract = new web3.eth.Contract(
		abi_tokenDefault,
		contractAddress
	);
	// console.log('here', msgKRK);
	if (msgKRK == "notBuy") {
		msgKRK = contractLaunchpad;
	} else if (msgKRK == "fairlaunch") {
		msgKRK = contractFairlaunch;
	} else if (msgKRK == "lock") {
		msgKRK = contractLock;
	}
	// console.log('here1', msgKRK);
	// console.log(contractAddress, msgKRK);

	let [
		name,
		symbol,
		decimals,
		balance,
		allowance,
		totalSupply,
		burnedAmount
	] = await Promise.all([
		contract.methods.name().call(),
		contract.methods.symbol().call(),
		contract.methods.decimals().call(),
		contract.methods.balanceOf(accounts[0]).call(),
		contract.methods.allowance(accounts[0], msgKRK).call(),
		contract.methods.totalSupply().call(),
		contract.methods.balanceOf("0x000000000000000000000000000000000000dead").call()
	]);
	let token0 = {}, token1 = {};
	try {
		const token0s = await contract.methods.token0().call();
		const contractTk = new web3.eth.Contract(
			abi_tokenDefault,
			token0s
		);
		let [
			tkname,
			tksymbol,
			tkdecimals
		] = await Promise.all([
			contractTk.methods.name().call(),
			contractTk.methods.symbol().call(),
			contractTk.methods.decimals().call()
		]);
		token0.tkname = tkname;
		token0.tksymbol = tksymbol;
		token0.tkdecimals = tkdecimals;
	} catch (error) {
		// console.log('no Lp'); 
		// token0 = {}
	}

	try {
		const token1s = await contract.methods.token1().call();
		const contractTk = new web3.eth.Contract(
			abi_tokenDefault,
			token1s
		);
		let [
			tkname,
			tksymbol,
			tkdecimals
		] = await Promise.all([
			contractTk.methods.name().call(),
			contractTk.methods.symbol().call(),
			contractTk.methods.decimals().call()
		]);
		token1.tkname = tkname;
		token1.tksymbol = tksymbol;
		token1.tkdecimals = tkdecimals;
	} catch (error) {
		// console.log('no Lp');
		// token1 = {}
	}

	return ({
		allowance: allowance / (10 ** decimals),
		balance: balance / (10 ** decimals),
		burnedAmount: burnedAmount / (10 ** decimals),
		decimals,
		name,
		symbol,
		totalSupply: totalSupply / (10 ** decimals),
		token0,
		token1
	})
	// return await contract.methods.getTokenInfo(contractAddress, accounts[0], contractLaunchpad).call();
};


const getInfoTokenBasic = async (contractAddress, msgKRK) => {
	// console.log(contractAddress, msgKRK);
	// return
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);


	if (!contractAddress) {
		return
	}

	const contract = new web3.eth.Contract(
		abi_tokenDefault,
		contractAddress
	);

	let [
		name,
		symbol,
		decimals
	] = await Promise.all([
		contract.methods.name().call(),
		contract.methods.symbol().call(),
		contract.methods.decimals().call()
	]);

	return ({
		decimals,
		name,
		symbol
	})
	// return await contract.methods.getTokenInfo(contractAddress, accounts[0], contractLaunchpad).call();
};


const getInfoTokenStake = async (contractAddress, msgKRK) => {
	// console.log(contractAddress, msgKRK);
	// return
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}

	if (!contractAddress) {
		return
	}

	const contract = new web3.eth.Contract(
		abi_tokenDefault,
		contractAddress
	);

	let [
		allowance,
		name,
		symbol,
		decimals,
		balanceStakeAddress,
		balance
	] = await Promise.all([
		contract.methods.allowance(accounts[0], msgKRK).call(),
		contract.methods.name().call(),
		contract.methods.symbol().call(),
		contract.methods.decimals().call(),
		contract.methods.balanceOf(msgKRK).call(),
		contract.methods.balanceOf(accounts[0]).call()
	]);

	return ({
		allowance: allowance / (10 ** decimals),
		decimals,
		name,
		symbol,
		balanceStakeAddress: balanceStakeAddress,
		balance: balance / (10 ** decimals)
	})
	// return await contract.methods.getTokenInfo(contractAddress, accounts[0], contractLaunchpad).call();
};

const createLaunchpad = async (data, msgType) => {
	// return
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	let _contractLaunchpad, _abi_launchpad;
	// console.log(msgType);
	if (msgType == "launchpad") {
		_contractLaunchpad = contractLaunchpad;
		_abi_launchpad = abi_launchpad;
	} else if (msgType == "fairlaunch") {
		_contractLaunchpad = contractFairlaunch;
		_abi_launchpad = abi_fairlaunch;
	}

	const factoryContract = new web3.eth.Contract(
		_abi_launchpad,
		_contractLaunchpad
	);

	const params1 = {
		currency: data.currency, // Address of the base token (currency)
		// textCurrency: data.textCurrency, // Name of the base token
		feeOption: data.feeOption, // Fee option
		listingOption: data.listingOption, // Listing option
		affiliate: data.affiliate, // Affiliate address
		totalTokenTo: (data.totalToken * 1).toFixed(0),
		decimals: data.decimalsContract, // Decimals of the base token
		contractToken: data.contractToken // Address of the new token (contractToken)
		// tokenName: data.tokenName, // Name of the new token
		// tokenSymbol: data.tokenSymbol, // Symbol of the new token

	};
	const params2 = {
		preRate: (data.preRate * 1).toFixed(0), // Conversion rate of the new token
		whiteList: data.whiteList, // Whitelist flag
		liquidityLockDay: Number(data.liquidityLockDay * (24 * 60 * 60)).toFixed(0), // Number of days for liquidity lock
		router: data.router,
		softCap: data.softCap * 1000, // Soft cap amount
		hardCap: msgType == "fairlaunch" ? 0 : data.hardCap * 1000, // Hard cap amount
		minBuy: data.minBuy * 1000, // Minimum value per buy
		maxBuy: data.maxBuy * 1000, // Maximum value per buy
		typeRefund: data.typeRefund, // Refund type
		liquidityRate: data.listingOption * 1 == 0 ? data.liquidityRate : 0, // Liquidity rate
		listingRate: data.listingOption * 1 == 0 ? (data.listingRate * 1).toFixed() : 0, // Listing rate
		startTime: Math.round(new Date(data.startTime).getTime() / 1000), // Start time
		endTime: Math.round(new Date(data.endTime).getTime() / 1000), // End time (1 day later)
		BDFSALE_LOCKER_ADDRESS: data.lock
	};
	const params3 = {
		logoUrl: data.logoUrl, // Logo image URL
		website: data.website, // Website address
		facebook: data.facebook, // Facebook address
		twitter: data.twitter, // Twitter address
		github: data.github, // GitHub address
		telegram: data.telegram, // Telegram address
		instagram: data.instagram, // Instagram address
		reddit: data.reddit, // Reddit address
		discord: data.discord, // Discord address
		youtube: data.youtube, // YouTube address
		description: data.description // Project description
	};
	const deverAddress = accounts[0]; // Developer address	

	// Send a transaction to create a new Launchpad
	// console.log(params1, params2, params3, deverAddress);
	// let amountBnbFee = new bigDecimal(1).multiply(bigUnit).value;

	// console.log(value);
	// return
	console.log(params1, params2, params3, deverAddress);


	let datas__s = await factoryContract.methods
		.createLaunchpad(params1, params2, params3)
		.send({ from: accounts[0], value: feeCreateLaunchpad }) // Send 1 BNB (18 decimals)
		.catch((error) => {
			console.error('Error:', error);
			return false
		});

	if (datas__s) {
		let objTK = {
			name: data.tokenName,
			symbol: data.tokenSymbol,
			decimals: data.decimalsContract
		}
		let chainText = "BSC";
		let chainId = await web3.eth.net.getId();
		switch (chainId) {
			case 1:
				chainText = 'ETH';
				break;
			case 97:
				chainText = 'BSC-Test';
				break;
			case 42161:
				chainText = 'ARB';
				break;
			default:
				chainText = 'BSC';
				break;
		}

		await fetch(`${domain}/addProjects?launchpadAddress=${datas__s?.events?.OwnershipTransferred?.address}&owner_=${accounts[0]}&tokenInfo=${JSON.stringify(objTK)}&params1=${JSON.stringify(params1)}&params2=${JSON.stringify(params2)}&params3=${JSON.stringify(params3)}&time=${(Date.now() / 1000).toFixed(0)}&chain=${chainText}`, {
			method: "GET",
		})
	}
	return datas__s;
};



const approve = async (totalToken, contractAddress, msgKRK) => {
	// return
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}

	let _dataIc = await getInfoTokenBasic(contractAddress, "notBuy");
	// if (dataIc) {
	// 	launchpadParamsStep1.tokenName = dataIc.name;
	// 	launchpadParamsStep1.tokenSymbol = dataIc.symbol;
	// }
	// console.log(_dataIc, contractAddress);
	// const value = web3.utils.toWei(Number(totalToken).toFixed(2), 'ether');
	const _bigUnit = new bigDecimal((10 ** _dataIc.decimals).toString());
	// let _bigUnit = new bigDecimal((10 ** _decimal).toString());
	// return await contract.methods.lock(
	// 	owner, token, true, new bigDecimal(amount).multiply(_bigUnit).value, date, _title
	// ).send({
	// 	from: userAddress
	// });

	const tokenContract = new web3.eth.Contract(abi_tokenDefault, contractAddress);
	if (msgKRK == "notBuy") {
		msgKRK = contractLaunchpad;
	} else if (msgKRK == "fairlaunch") {
		msgKRK = contractFairlaunch;
	} else if (msgKRK == "lock") {
		msgKRK = contractLock;
	} else if (msgKRK == "sender") {
		msgKRK = contractSender;
	} else if (msgKRK == "stake") {
		msgKRK = contractCreateBankStaking;
	}

	return await tokenContract.methods.approve(msgKRK, new bigDecimal(totalToken).multiply(_bigUnit).value)
		.send({ from: accounts[0] })
		.catch((error) => {
			console.error('Error:', error);
			return false;
		});

};



const infoLaunchpadSV = async (contractAddress) => {
	try {
		if (!window.localStorage.getItem("listLaunchpads")) {
			return
		}
		let arr = JSON.parse(window.localStorage.getItem("listLaunchpads"))

		for (let index = 0; index < arr.data.length; index++) {
			const element = arr.data[index];
			if (element.launchpadAddress.toLocaleLowerCase() == contractAddress.toLocaleLowerCase()) {
				return element;
			}
		}
		return {};
	} catch (err) {
		return false;
	}
}

const getListTrending = async () => {
	try {
		if (!window.localStorage.getItem("listLaunchpads")) {
			return
		}
		let arr = JSON.parse(window.localStorage.getItem("listLaunchpads"))
		arr = arr.data;
		// console.log(arr);

		arr.sort((a, b) => b.point - a.point);
		// console.log(arr);
		let arrTrending_ = [];

		for (let index = 0; index < 12; index++) {
			const element = arr[index];
			if (element) {
				arrTrending_.push(element)
			}
		}
		return arrTrending_;
	} catch (err) {
		return false;
	}
}

const searchLaunchpad = async (text) => {
	try {
		if (!window.localStorage.getItem("listLaunchpads")) {
			return
		}
		let arr = JSON.parse(window.localStorage.getItem("listLaunchpads"))
		let arrFt = [];
		for (let index = 0; index < arr.data.length; index++) {
			const element = arr.data[index];
			let launchpadAddress = element.launchpadAddress.toLocaleLowerCase();
			let { name, symbol, decimals } = JSON.parse(element.tokenInfo);
			name = name.toLocaleLowerCase();
			symbol = symbol.toLocaleLowerCase();
			decimals = decimals.toLocaleLowerCase();

			let tottalTxg = launchpadAddress + ' ' + name + ' ' + symbol + ' ' + decimals;

			if (tottalTxg.indexOf(text) != -1) {
				arrFt.push(element);
			}
		}
		// console.log('done1');
		return arrFt;
	} catch (err) {
		return false;
	}
}



const getAllLaunchpadSever = async () => {
	// if (domain == undefined) {
	// 	setTimeout(() => {
	// 		getAllLaunchpadSever()
	// 	}, 1000);
	// }
	// try {
	// 	const res = await fetch(`${domain}/listLaunchpads`, {
	// 		method: "GET",
	// 	}).then((res) => res.json()).catch(e => {
	// 		// console.log(e);
	// 	});
	// 	// console.log(res);
	// 	if (!res.data) {
	// 		return
	// 	}
	// 	let objcv = {};
	// 	objcv.data = res.data;
	// 	// console.log(objcv.data);
	// 	window.localStorage.setItem('listLaunchpads', JSON.stringify(objcv))
	// 	return res;
	// } catch (err) {
		return false;
	// }
}

const getWalletsAndCommissionBalances = async (total_, contractAddress) => {
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	const contract = new web3.eth.Contract(
		abi_childLaunchpad,
		contractAddress
	);

	let normalLockIds = Array.from({ length: total_ }, (_, i) => i);
	let normalLocks = await Promise.all(normalLockIds.map(async (_dt) => {
		// console.log(_dt);
		return await contract.methods._historyRef(_dt).call();
	}));
	// let obj_ = {}
	// console.log(normalLocks);

	const commissionBalances = {};

	// Process the data array and calculate commission balances
	normalLocks.forEach(item => {
		const wallet = item.ref;
		const amount = parseFloat(item.amount) / 1e18; // Convert wei to ether

		if (commissionBalances[wallet]) {
			commissionBalances[wallet] += amount;
		} else {
			commissionBalances[wallet] = amount;
		}
	});

	// Convert commissionBalances object to an array of objects
	// const result = Object.keys(commissionBalances).map(wallet => ({
	// 	wallet,
	// 	commissionBalance: commissionBalances[wallet],
	// }));

	const result = Object.keys(commissionBalances)
		.filter(wallet => commissionBalances[wallet] > 0)
		.map(wallet => ({
			wallet,
			commissionBalance: commissionBalances[wallet],
		}));

	result.sort((a, b) => b.commissionBalance - a.commissionBalance);
	// result = result.filter(a => a.commissionBalance > 0)
	return result
	// console.log(result) 
}

//child launchpad
const getLaunchpad = async (contractAddress) => {

	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	// console.log(web3);
	// console.log(await web3.eth.getAccounts());
	let accounts = await web3.eth.getAccounts();
	// console.log(accounts);
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	// console.log("run st1");
	const contract = new web3.eth.Contract(
		abi_childLaunchpad,
		contractAddress
	);
	// console.log("run st2");
	try {
		let [
			launchpadParamsStep1,
			launchpadParamsStep2,
			launchpadParamsStep3,
			totalBuyed,
			deverAddress,
			isSaleActive,
			totalContributors,
			totalCommission,
			totalWhitelist,
			whitelistAddresses,
			timePublicWls,
			dataSv,
			dataLockToken,
			totalBuyM,
			claimedM,
			commissionM,
			totalHistoryRefs,
			ref,
			claimedCommission
		] = await Promise.all([
			contract.methods.launchpadParamsStep1().call(),
			contract.methods.launchpadParamsStep2().call(),
			contract.methods.launchpadParamsStep3().call(),
			contract.methods.totalBuyed().call(),
			contract.methods.deverAddress().call(),
			contract.methods.isSaleActive().call(),
			contract.methods.totalContributors().call(),
			contract.methods.totalCommission().call(),
			contract.methods.totalWhitelist().call(),
			contract.methods.getWhitelist().call(),
			contract.methods.timePublicWls().call(),
			infoLaunchpadSV(contractAddress),
			getTokenLocks(contractAddress),
			contract.methods.balances(accounts[0]).call(),
			contract.methods.claimed(accounts[0]).call(),
			contract.methods.commission(accounts[0]).call(),
			contract.methods.totalHistoryRefs().call(),
			contract.methods.ref(accounts[0]).call(),
			contract.methods.claimedCommission(accounts[0]).call()

		]);
		// console.log(timePublicWls);
		// return

		let dataIc = await getInfoTokenBasic(launchpadParamsStep1?.contractToken, "notBuy");
		if (dataIc) {
			launchpadParamsStep1.tokenName = dataIc.name;
			launchpadParamsStep1.tokenSymbol = dataIc.symbol;
		}

		let arrRe = [];
		for (let index = 0; index < dataLockToken.length; index++) {
			const element = dataLockToken[index];
			element.amount = element.amount / (10 ** dataIc?.decimals);
			arrRe.push(element)
		}
		dataLockToken = arrRe;

		switch (launchpadParamsStep1?.currency) {
			case "0x0000000000000000000000000000000000000000":
				launchpadParamsStep1.textCurrency = "BNB"
				break;
			case "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":
				launchpadParamsStep1.textCurrency = "BUSD"
				break;
			case "0x55d398326f99059fF775485246999027B3197955":
				launchpadParamsStep1.textCurrency = "USDT"
				break;
			case "0x0F238B9632426fC2E708B9c4867D66808E532e67":
				launchpadParamsStep1.textCurrency = "USDC"
				break;
			default:
				break;
		}
		// console.log(totalHistoryRefs);
		// getWalletsAndCommissionBalances

		return (
			{
				launchpadParamsStep1,
				launchpadParamsStep2,
				launchpadParamsStep3,
				totalBuyed,
				deverAddress,
				isSaleActive,
				totalContributors,
				totalCommission,
				totalWhitelist,
				whitelistAddresses,
				timePublicWls,
				dataSv,
				dataLockToken,
				totalBuyM,
				claimedM,
				commissionM,
				totalHistoryRefs,
				ref,
				claimedCommission
			}
		)
	} catch (error) {
		return
	}
};

const getLaunchpadNotToken = async (contractAddress) => {

	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);


	// console.log("run st1");
	const contract = new web3.eth.Contract(
		abi_childLaunchpad,
		contractAddress
	);
	// console.log("run st2");
	try {
		let [
			launchpadParamsStep1,
			launchpadParamsStep2,
			launchpadParamsStep3,
			totalBuyed,
			deverAddress,
			isSaleActive,
			totalContributors,
			totalCommission,
			timePublicWls,
			dataSv,
		] = await Promise.all([
			contract.methods.launchpadParamsStep1().call(),
			contract.methods.launchpadParamsStep2().call(),
			contract.methods.launchpadParamsStep3().call(),
			contract.methods.totalBuyed().call(),
			contract.methods.deverAddress().call(),
			contract.methods.isSaleActive().call(),
			contract.methods.totalContributors().call(),
			contract.methods.totalCommission().call(),
			contract.methods.timePublicWls().call(),
			infoLaunchpadSV(contractAddress),
		]);

		let dataIc = await getInfoTokenBasic(launchpadParamsStep1?.contractToken, "notBuy");
		if (dataIc) {
			launchpadParamsStep1.tokenName = dataIc.name;
			launchpadParamsStep1.tokenSymbol = dataIc.symbol;
		}

		switch (launchpadParamsStep1?.currency) {
			case "0x0000000000000000000000000000000000000000":
				launchpadParamsStep1.textCurrency = "BNB"
				break;
			case "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":
				launchpadParamsStep1.textCurrency = "BUSD"
				break;
			case "0x55d398326f99059fF775485246999027B3197955":
				launchpadParamsStep1.textCurrency = "USDT"
				break;
			case "0x0F238B9632426fC2E708B9c4867D66808E532e67":
				launchpadParamsStep1.textCurrency = "USDC"
				break;
			default:
				break;
		}
		return (
			{
				launchpadParamsStep1,
				launchpadParamsStep2,
				launchpadParamsStep3,
				totalBuyed,
				deverAddress,
				isSaleActive,
				totalContributors,
				totalCommission,
				timePublicWls,
				dataSv,
			}
		)
	} catch (error) {
		return
	}
};



const getTokenLocks = async (tokenAddress) => {

	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	if (!tokenAddress) {
		return
	}

	const contract = new web3.eth.Contract(abi_lock, contractLock);
	try {
		const lockCount = await contract.methods
			.totalLockCountForToken(tokenAddress)
			.call();
		console.log(lockCount);
		const lockDetails = [];
		for (let i = 0; i < lockCount; i++) {
			const lock = await contract.methods
				.getLocksForToken(tokenAddress, i, i)
				.call();
			// console.log(lock[0]);
			const lockId = lock[0].id;
			const description = lock[0].description;
			const amount = lock[0].amount;
			const cycle = lock[0].cycle;
			const tgeDate = new Date(lock[0].tgeDate * 1000);
			const unlockDate = new Date(lock[0].tgeDate * 1000);
			const cycleBps = lock[0].cycleBps;
			const lockDate = lock[0].lockDate;
			const tgeBps = lock[0].tgeBps;
			const owner = lock[0].owner;
			const unlockedAmount = lock[0].unlockedAmount;

			lockDetails.push({
				cycleBps,
				lockDate,
				tgeBps,
				unlockedAmount,
				lockId,
				description,
				amount,
				cycle,
				tgeDate,
				unlockDate,
				owner
			});
		}

		return lockDetails;
	} catch (error) {
		console.error('Error:', error);
		return null;
	}
}


// async function estimateGas(methodArgs, fromAddress, contract) {
// 	console.log(methodArgs, fromAddress, contract);
// 	try {
// 		const gas = await contract.methods["contribute"](...methodArgs).estimateGas({ from: fromAddress });
// 		console.log(`Estimated gas for the transaction: ${gas}`);
// 	} catch (error) {
// 		console.error('Error estimating gas: ', error);
// 	}
// }

async function estimateGas(amount, tokenAddress, fromAddress, contract) {
	// console.log(methodArgs, fromAddress, contract);
	try {
		const gas = await contract.methods.contribute(amount, tokenAddress).estimateGas({ from: fromAddress, value: amount });
		console.log(`Estimated gas for the transaction: ${gas}`);
	} catch (error) {
		console.error('Error estimating gas: ', error);
	}
}

const contributeToContract = async (amount, launchpadAddress, tokenAddress, typeLaunch) => {

	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);
		// console.log(web3);
		let accounts = await web3.eth.getAccounts();
		// console.log(accounts);
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}

		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		const balanceInWei = await web3.eth.getBalance(userAddress);
		const balanceInBNB = web3.utils.fromWei(balanceInWei, 'ether');
		// console.log(balanceInBNB);
		if (balanceInBNB * 1 > amount * 1 + 0.0001) {
			amount = web3.utils.toWei(Number(amount).toString(), 'ether');
		} else {
			const gasPrice = await web3.eth.getGasPrice();
			const gasLimit = 100000;
			const contributionValue = web3.utils.toWei(balanceInBNB, 'ether');
			const transactionFee = web3.utils.toBN(gasPrice).mul(web3.utils.toBN(gasLimit));
			const maxBNBToBuyInWei = web3.utils.toBN(contributionValue).sub(transactionFee);
			amount = maxBNBToBuyInWei
		}
		// let gasswe = await estimateGas(amount, tokenAddress, userAddress, contract);
		// console.log(gasswe);
		// return
		return await contract.methods.contribute(amount, tokenAddress).send({
			from: userAddress,
			value: amount,
		});
	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const contributeWithToken = async (amount, launchpadAddress, tokenAddress, typeLaunch) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		amount = web3.utils.toWei(Number(amount).toFixed(6), 'ether');

		return await contract.methods.contributeWithToken(amount, tokenAddress).send({
			from: userAddress
		});
	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const unContribute = async (launchpadAddress) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		// amount = web3.utils.toWei(Number(amount).toFixed(6), 'ether');

		return await contract.methods.unContribute().send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const changeSaleType = async (_type, launchpadAddress) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		return await contract.methods.changeSttWhiteList(_type).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const updateTimePublicSale = async (_time, launchpadAddress) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		return await contract.methods.updateTimePublic(Math.round(new Date(_time).getTime() / 1000)).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const addWhitelist = async (_arrWhite, launchpadAddress) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		return await contract.methods.addToWhitelist(_arrWhite).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const removeWhitelist = async (_arrWhite, launchpadAddress) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		return await contract.methods.removeFromWhitelist(_arrWhite).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const claimToken = async (launchpadAddress) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		return await contract.methods.claimTokens().send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const claimCommission = async (launchpadAddress) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		// console.log(launchpadAddress);
		// return
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		return await contract.methods.withdrawAllCommission().send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const finalize = async (launchpadAddress) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		return await contract.methods.withdrawFunds().send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};


const cancelPool = async (launchpadAddress) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		return await contract.methods.cancel().send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const updateLaunchpad = async (data, launchpadAddress) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		const params3 = {
			logoUrl: data.logoUrl, // Logo image URL
			website: data.website, // Website address
			facebook: data.facebook, // Facebook address
			twitter: data.twitter, // Twitter address
			github: data.github, // GitHub address
			telegram: data.telegram, // Telegram address
			instagram: data.instagram, // Instagram address
			reddit: data.reddit, // Reddit address
			discord: data.discord, // Discord address
			youtube: data.youtube, // YouTube address
			description: data.description // Project description
		};

		return await contract.methods.changeInfoSocialNetwork(params3).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const updateAffiliate = async (_percent, launchpadAddress) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childLaunchpad, launchpadAddress);
		const userAddress = accounts[0];

		return await contract.methods.updateAffiliate(_percent).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};


const lockToken = async ({ _amount, _owner, isLpToken, _token, _date, _decimal, _title }) => {

	// return
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		if (_owner == "") {
			_owner = accounts[0]
		}
		const contract = new web3.eth.Contract(abi_lock, contractLock);
		const userAddress = accounts[0];
		let _bigUnit = new bigDecimal((10 ** _decimal).toString());
		// console.log(_owner, _token, false, new bigDecimal(_amount).multiply(_bigUnit).value, _date, _title);
		return await contract.methods.lock(
			_owner, _token, isLpToken, new bigDecimal(_amount).multiply(_bigUnit).value, _date, _title
		).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const vestingLockToken = async ({ _amount, isLpToken, _owner, _token, _date, _decimal, _title, _tgeBps, _cycle, _cycleBps }) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		if (_owner == "") {
			_owner = accounts[0]
		}
		const contract = new web3.eth.Contract(abi_lock, contractLock);
		const userAddress = accounts[0];
		let _bigUnit = new bigDecimal((10 ** _decimal).toString());
		return await contract.methods.vestingLock(
			_owner, _token, isLpToken, new bigDecimal(_amount).multiply(_bigUnit).value, _date, _tgeBps, _cycle, _cycleBps, _title
		).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const unLock = async (id) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_lock, contractLock);
		const userAddress = accounts[0];
		return await contract.methods.unlock(id).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

// function formatNumber(number) {
// 	// console.log(number);
// 	if (!number) {
// 		return number
// 	}
// 	const numberStr = number.toString();

// 	const [integerPart, decimalPart] = numberStr.split('.');

// 	const formattedIntegerPart = parseInt(integerPart).toLocaleString();

// 	const formattedDecimalPart = decimalPart ? '' : decimalPart;

// 	return formattedDecimalPart
// 		? `${formattedIntegerPart}.${formattedDecimalPart}`
// 		: formattedIntegerPart;
// }
function formatNumber(number, decimalPlaces = 4) {
	if (number === null || number === undefined) {
		return number;
	}

	const roundedNumber = Number(Number(number).toFixed(decimalPlaces)).toString();

	const [integerPart, decimalPart] = roundedNumber.split('.');

	const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
}

const infoTokenLock = async (arrTokenLock, _type) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		const contract = new web3.eth.Contract(abi_lock, contractLock);
		let lsdfrt;
		if (_type !== 'all') {
			lsdfrt = arrTokenLock;
			// return arrTokenLock;
			// normalLocks = await Promise.all(count.map(async (_dt) => {
			// 	let obj = {};
			// 	let _dataIc = await getInfoToken(_dt.token, "notBuy");
			// 	obj.amount = _dt.amount;
			// 	obj.decimals = _dataIc.decimals;
			// 	obj.name = _dataIc.name;
			// 	obj.symbol = _dataIc.symbol;
			// 	return obj;
			// }));
		} else {
			lsdfrt = await Promise.all(arrTokenLock.map(id => contract.methods.getLockAt(id).call()));
		}
		// console.log(arrTokenLock);
		// return

		let normalLocks = await Promise.all(lsdfrt.map(async (_dt) => {
			let obj = {};
			let _dataIc = await getInfoToken(_dt.token, "notBuy");
			obj.amount = _dt.amount;
			obj.token = _dt.token;
			obj.decimals = _dataIc.decimals;
			obj.name = _dataIc.name;
			obj.symbol = _dataIc.symbol;
			obj.token0 = _dataIc.token0;
			obj.token1 = _dataIc.token1;
			return obj;
		}));

		// normalLocks = normalLocks.filter((dt) => {
		// 	if (dt.token0.tkname == undefined) {
		// 		return dt
		// 	}
		// });

		// console.log(normalLocks);
		normalLocks.sort((a, b) => b.lockDate - a.lockDate);
		return normalLocks;

	} catch (error) {
		console.error('Error contributing:', error);
		return [];
	}
};

const listTokenLock = async (_type) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}

		const contract = new web3.eth.Contract(abi_lock, contractLock);
		let count, normalLocks = [], normalLockIds, lsdfrt;
		if (_type == 'all') {
			count = await contract.methods.getTotalLockCount().call()
			normalLockIds = Array.from({ length: count }, (_, i) => i);
			normalLocks = normalLockIds;
			normalLocks.sort((a, b) => b - a);
		} else if (_type == "normal") {
			count = await contract.methods.normalLocksForUser(accounts[0]).call();
			normalLocks = await Promise.all(count.map(async (_dt) => {
				let obj = {};
				obj.amount = _dt.amount;
				obj.token = _dt.token;
				obj.id = _dt.id * 1;
				obj.lockDate = _dt.lockDate * 1;
				return obj;
			}));
			normalLocks.sort((a, b) => b.lockDate - a.lockDate);
		} else if (_type == "lp") {
			count = await contract.methods.lpLocksForUser(accounts[0]).call();
			normalLocks = await Promise.all(count.map(async (_dt) => {
				let obj = {};
				obj.amount = _dt.amount;
				obj.token = _dt.token;
				obj.id = _dt.id * 1;
				obj.lockDate = _dt.lockDate * 1;
				return obj;
			}));
			normalLocks.sort((a, b) => b.lockDate - a.lockDate);
		} else {
			// console.log("_type", _type);
			if (!isValidAddress(_type)) {
				return [];
			}
			const lockCount = await contract.methods
				.totalLockCountForToken(_type)
				.call();
			const lockDetails = {
				amount: 0,
				token: "",
				id: "",
				lockDate: ""
			};
			for (let i = 0; i < lockCount; i++) {
				const lock = await contract.methods
					.getLocksForToken(_type, i, i)
					.call();
				// console.log(lock);
				lockDetails.amount += Number(lock[0].amount);
				lockDetails.lockDate = lock[0].lockDate;
				lockDetails.token = lock[0].token;
				lockDetails.id = lock[0].id;
			}
			// console.log(lockDetails);
			if (lockDetails?.amount > 0) {
				normalLocks.push(lockDetails);
			}
		}
		// console.log(normalLocks);
		return normalLocks;

	} catch (error) {
		console.error('Error contributing:', error);
		return [];
	}
};


const getLockById = async (_id) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_lock, contractLock);

		return await contract.methods.getLockById(_id).call()
	} catch (error) {
		console.error('Error contributing:', error);
		return [];
	}
};

const isValidAddress = (address) => {
	try {
		// Check if the address has a valid length and starts with '0x'
		if (address.length !== 42 || !address.startsWith('0x')) {
			return false;
		}

		// Use web3.utils.isAddress to check the address validity
		const web3 = new Web3();
		return web3.utils.isAddress(address);
	} catch (error) {
		return false;
	}
};

const formatTimestamp = (date) => {
	const formattedDate = new Date(date);
	const year = formattedDate.getUTCFullYear();
	const month = String(formattedDate.getUTCMonth() + 1).padStart(2, '0');
	const day = String(formattedDate.getUTCDate()).padStart(2, '0');
	const hours = String(formattedDate.getUTCHours()).padStart(2, '0');
	const minutes = String(formattedDate.getUTCMinutes()).padStart(2, '0');

	return `${year}.${month}.${day} ${hours}:${minutes}`;
};

const jsonPares = (data_) => {
	if (!data_) {
		return {}
	}
	return JSON.parse(data_);
};

const transferLockOwner = async (lockId, newOwner) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_lock, contractLock);
		const userAddress = accounts[0];

		return await contract.methods.transferLockOwnership(lockId, newOwner).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const renounceLockOwner = async (idRecordTk) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_lock, contractLock);
		const userAddress = accounts[0];

		return await contract.methods.renounceLockOwnership(idRecordTk).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const editLock = async (idRecordTk, newamount, newtime, _decimal) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_lock, contractLock);
		const userAddress = accounts[0];

		let _bigUnit = new bigDecimal((10 ** _decimal).toString());
		return await contract.methods.editLock(idRecordTk, new bigDecimal(newamount).multiply(_bigUnit).value, newtime).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const editLockDescription = async (idRecordTk, title) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_lock, contractLock);
		const userAddress = accounts[0];

		return await contract.methods.editLockDescription(idRecordTk, title).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const createStandardToken = async (name_, symbol_, decimals_, totalSupply_) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_createStandardToken, contractCreateStandardToken);
		const userAddress = accounts[0];

		let _bigUnit = new bigDecimal((10 ** decimals_).toString());
		totalSupply_ = new bigDecimal(totalSupply_).multiply(_bigUnit).value;

		return await contract.methods.createStandardToken(name_, symbol_, decimals_, totalSupply_).send({
			from: userAddress,
			value: feeCreateToken
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};
const createAntiBotStandardToken = async (name_, symbol_, decimals_, totalSupply_) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_createStandardToken, contractCreateStandardToken);
		const userAddress = accounts[0];
		let _bigUnit = new bigDecimal((10 ** decimals_).toString());
		totalSupply_ = new bigDecimal(totalSupply_).multiply(_bigUnit).value;
		return await contract.methods.createAntiBotStandardToken(name_, symbol_, decimals_, totalSupply_, addresAntibot).send({
			from: userAddress,
			value: feeCreateToken
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const createLiquidityGeneratorToken = async (
	name_,
	symbol_,
	totalSupply_,
	router_,
	charityAddress_,
	taxFeeBps_,
	liquidityFeeBps_,
	charityFeeBps_
) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_createLiquidityGeneratorToken, contractCreateLiquidityGeneratorToken);
		const userAddress = accounts[0];


		let _bigUnit = new bigDecimal((10 ** 9).toString());
		totalSupply_ = new bigDecimal(totalSupply_).multiply(_bigUnit).value;

		return await contract.methods.createToken(
			name_,
			symbol_,
			totalSupply_,
			router_,
			charityAddress_,
			taxFeeBps_,
			liquidityFeeBps_,
			charityFeeBps_
		).send({
			from: userAddress,
			value: feeCreateToken
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const createAntiBotLiquidityGeneratorToken = async (
	name_,
	symbol_,
	totalSupply_,
	router_,
	charityAddress_,
	taxFeeBps_,
	liquidityFeeBps_,
	charityFeeBps_
) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_createAntiBotLiquidityGeneratorToken, contractCreateAntiBotLiquidityGeneratorToken);
		const userAddress = accounts[0];

		let _bigUnit = new bigDecimal((10 ** 9).toString());
		totalSupply_ = new bigDecimal(totalSupply_).multiply(_bigUnit).value;

		return await contract.methods.createToken(
			name_,
			symbol_,
			totalSupply_,
			router_,
			charityAddress_,
			taxFeeBps_,
			liquidityFeeBps_,
			charityFeeBps_,
			addresAntibot
		).send({
			from: userAddress,
			value: feeCreateToken
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const createBABYTOKEN = async (
	name_,
	symbol_,
	totalSupply_,
	addrs,
	feeSettings,
	minimumTokenBalanceForDividends_
) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_createBABYTOKEN, contractCreateBABYTOKEN);
		const userAddress = accounts[0];

		let _bigUnit = new bigDecimal((10 ** 18).toString());
		totalSupply_ = new bigDecimal(totalSupply_).multiply(_bigUnit).value;

		return await contract.methods.createToken(
			name_,
			symbol_,
			totalSupply_,
			addrs,
			feeSettings,
			minimumTokenBalanceForDividends_
		).send({
			from: userAddress,
			value: feeCreateToken
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const createAntiBotBABYTOKEN = async (
	name_,
	symbol_,
	totalSupply_,
	addrs,
	feeSettings,
	minimumTokenBalanceForDividends_
) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_createAntiBotBABYTOKEN, contractCreateAntiBotBABYTOKEN);
		const userAddress = accounts[0];

		let _bigUnit = new bigDecimal((10 ** 18).toString());
		totalSupply_ = new bigDecimal(totalSupply_).multiply(_bigUnit).value;

		return await contract.methods.createToken(
			name_,
			symbol_,
			totalSupply_,
			addrs,
			feeSettings,
			minimumTokenBalanceForDividends_
		).send({
			from: userAddress,
			value: feeCreateToken
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const createBuyBackBabyToken = async (
	name_,
	symbol_,
	totalSupply_,
	rewardToken_,
	router_,
	feeSettings_
) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_createBuybackBabyToken, contractCreateBuybackBabyToken);
		const userAddress = accounts[0];

		let _bigUnit = new bigDecimal((10 ** 18).toString());
		totalSupply_ = new bigDecimal(totalSupply_).multiply(_bigUnit).value;

		return await contract.methods.createToken(
			name_,
			symbol_,
			totalSupply_,
			rewardToken_,
			router_,
			feeSettings_
		).send({
			from: userAddress,
			value: feeCreateToken
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const getTotalLaunchpad = async () => {

	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);
		// console.log(abi_listlaunch, contractListLaunch, contractsAllChain, web3);
		const contract = new web3.eth.Contract(abi_listlaunch, contractListLaunch);
		return await contract.methods.totalLaunchpads().call()
	} catch (error) {
		console.error('Error contributing:', error);
		return [];
	}
};

const getTotalStake = async () => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);
		console.log(web3);
		const contract = new web3.eth.Contract(abi_liststake, contractListStake);

		return await contract.methods.totalStake().call()
	} catch (error) {
		console.error('Error contributing:', error);
		return [];
	}
};

const getStakeNotToken = async (contractAddress) => {

	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}

	// console.log("run st1");
	const contract = new web3.eth.Contract(
		abi_childStake,
		contractAddress
	);
	// console.log("run st2");
	try {
		let [
			totalPackage,
			timeCanUnlock,
			percentWithdraw,
			percentCommissionF1,
			minStake,
			maxStake,
			percentProfit,
			maxOut,
			owner,
			_joinToken,
			receiveAddress
		] = await Promise.all([
			contract.methods.totalPackage().call(),
			contract.methods.timeCanUnlock().call(),
			contract.methods.percentWithdraw().call(),
			contract.methods.percentCommissionF1().call(),
			contract.methods.minStake().call(),
			contract.methods.maxStake().call(),
			contract.methods.percentProfit().call(),
			contract.methods.maxOut().call(),
			contract.methods.owner().call(),
			contract.methods._joinToken().call(),
			contract.methods.receiveAddress().call()

		]);
		let userDeposit = {}, pool = 0;
		try {
			userDeposit = await contract.methods._stake(accounts[0]).call()
				.catch(e => {
					console.log(e);
				})
		} catch (error) {
			console.log(error);
		}

		let dataIc = await getInfoTokenStake(_joinToken, contractAddress);
		if (dataIc) {
			pool = dataIc.balanceStakeAddress
		}

		return (
			{
				totalPackage,
				timeCanUnlock,
				percentWithdraw,
				percentCommissionF1,
				minStake,
				maxStake,
				percentProfit,
				maxOut,
				pool,
				dataIc,
				_joinToken,
				userDeposit,
				owner,
				receiveAddress
			}
		)
	} catch (error) {
		return
	}
};

const getListStake = async (start, end) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		const contract = new web3.eth.Contract(abi_liststake, contractListStake);

		let data_Ll = await contract.methods.getStakesInRange(start, end).call();
		// console.log(data_Ll);

		let normalLocks = await Promise.all(data_Ll.map(async (_dt) => {
			// console.log(_dt.stakeAddress);
			let _dataIc = await getStakeNotToken(_dt.stakeAddress);
			// console.log(_dataIc);
			if (_dataIc) {
				_dataIc.stakeAddress = _dt.stakeAddress;
				_dataIc.tokenAddress = _dataIc._joinToken;
				return _dataIc;
			}
		}));

		// console.log(normalLocks);
		// normalLocks.filter()
		normalLocks = normalLocks.filter(a => a)
		// console.log(normalLocks);
		// console.log(Date.now() - sdlslsl);
		normalLocks = normalLocks.reverse()
		return normalLocks;
	} catch (error) {
		console.error('Error contributing:', error);
		return [];
	}
};

const getListLaunchpad = async (start, end) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);


		const contract = new web3.eth.Contract(abi_listlaunch, contractListLaunch);

		let data_Ll = await contract.methods.getLaunchpadsInRange(start, end).call();

		let normalLocks = await Promise.all(data_Ll.map(async (_dt) => {
			// console.log(_dt);
			let _dataIc = await getLaunchpadNotToken(_dt.launchpadAddress);
			// console.log(_dataIc);
			if (_dataIc) {
				_dataIc.launchpadAddress = _dt.launchpadAddress;
				return _dataIc;
			}
		}));

		// console.log(normalLocks);
		// normalLocks.filter()
		normalLocks = normalLocks.filter(a => a)
		// console.log(normalLocks);
		// console.log(Date.now() - sdlslsl);
		normalLocks = normalLocks.reverse()
		let reArr = [];
		for (let index = 0; index < normalLocks.length; index++) {
			const element = normalLocks[index];
			if (element.launchpadAddress != "0xe68FffC08093175FBFBca5EB6793895DeF1b9ED1" &&
				element.launchpadAddress != "0xd4F61245666C14D820EC18d60C1D1605317d6C13" &&
				element.launchpadAddress != "0xD67097978c7a1FaE99859B69D8481eeeCDF9D1eD") {
				reArr.push(element)
			}
		}
		return reArr;
	} catch (error) {
		console.error('Error contributing:', error);
		return [];
	}
};


const getMyListStake = async () => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_liststake, contractListStake);
		let data_Ll = await contract.methods.getStakesByOwner(accounts[0]).call()
		let normalLocks = await Promise.all(data_Ll.map(async (_dt) => {
			let _dataIc = await getStakeNotToken(_dt.stakeAddress);
			if (_dataIc) {
				_dataIc.stakeAddress = _dt.stakeAddress;
				_dataIc.tokenAddress = _dataIc._joinToken;
				return _dataIc;
			}
		}));
		normalLocks = normalLocks.reverse()
		return normalLocks;
	} catch (error) {
		console.error('Error contributing:', error);
		return [];
	}
};

const getMyListLaunchpad = async () => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_listlaunch, contractListLaunch);

		// return await contract.methods.getLaunchpadsByOwner(accounts[0]).call()
		let data_Ll = await contract.methods.getLaunchpadsByOwner(accounts[0]).call()
		let normalLocks = await Promise.all(data_Ll.map(async (_dt) => {
			let _dataIc = await getLaunchpadNotToken(_dt?.launchpadAddress);
			if (_dataIc) {
				_dataIc.launchpadAddress = _dt?.launchpadAddress;
				return _dataIc;
			}
		}));
		normalLocks = normalLocks.reverse()
		return normalLocks;
	} catch (error) {
		console.error('Error contributing:', error);
		return [];
	}
};

const getMyBuyLaunchpad = async () => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_listbuy, contractListBuy);

		// return await contract.methods.getLaunchpadsByOwner(accounts[0]).call()
		let data_Ll = await contract.methods.getBuysByOwner(accounts[0]).call()

		const commissionBalances = {};

		data_Ll.forEach(item => {
			const launchpadAddress = item.launchpadAddress;
			if (!commissionBalances[launchpadAddress]) {
				commissionBalances[launchpadAddress] = item.owner_;
			}
		});
		const result = Object.keys(commissionBalances).map(launchpadAddress => ({
			launchpadAddress,
			owner_: commissionBalances[launchpadAddress],
		}));

		let normalLocks = await Promise.all(result.map(async (_dt) => {
			let _dataIc = await getLaunchpadNotToken(_dt?.launchpadAddress);
			if (_dataIc) {
				_dataIc.launchpadAddress = _dt?.launchpadAddress;
				return _dataIc;
			}
		}));
		normalLocks = normalLocks.reverse()
		return normalLocks;
	} catch (error) {
		console.error('Error contributing:', error);
		return [];
	}
};



const calculateTimeAfterPoolEnds = (time, textsf) => {
	const days = Math.floor(time);
	const hours = Math.floor((time % 1) * 24);
	const minutes = Math.floor((((time % 1) * 24) % 1) * 60);

	let result = "";

	if (days >= 1) {
		result += `${days} day${days > 1 ? "s" : ""}`;
	} else if (hours >= 1) {
		result += `${hours} hour${hours > 1 ? "s" : ""}`;
	} else {
		result += `${minutes} minute${minutes > 1 ? "s" : ""}`;
	}

	result += (" " + textsf);
	return result;
};

const multisendToken = async (token, ensureExactAmount, targets, amounts, decimal_) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_sender, contractSender);
		const userAddress = accounts[0];

		let amounts_ = [];
		for (let index = 0; index < amounts.length; index++) {
			const element = amounts[index];
			let _bigUnit = new bigDecimal((10 ** decimal_).toString());
			amounts_.push(new bigDecimal(element).multiply(_bigUnit).value)
		}

		return await contract.methods.multisendToken(
			token, ensureExactAmount, targets, amounts_
		).send({
			from: userAddress
		});
	} catch (error) {
		console.log(error);
		return
	}

}

const multisendCoin = async (_contributors, _balances, totalAm) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_sender, contractSender);
		const userAddress = accounts[0];
		let _bigUnit = new bigDecimal((10 ** 18).toString());

		let amounts_ = [];
		for (let index = 0; index < _balances.length; index++) {
			const element = _balances[index];

			amounts_.push(new bigDecimal(element).multiply(_bigUnit).value)
		}

		let totalAm_ = new bigDecimal(totalAm).multiply(_bigUnit).value

		// console.log(_contributors, _balances, totalAm_);
		return await contract.methods.multisendEther(
			_contributors, amounts_
		).send({
			from: userAddress,
			value: totalAm_
		});

	} catch (error) {
		console.log(error);
		return
	}
}

const editStake = async (
	stakeAddress,
	_timeCanUnlock,
	_percentWithdraw,
	_percentCommissionF1,
	_minStake,
	_maxStake,
	_percentProfit,
	_maxOut,
	_receiveAddress,
	decimals_
) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_childStake, stakeAddress);
		const userAddress = accounts[0];

		let _bigUnit = new bigDecimal((10 ** decimals_).toString());

		_minStake = new bigDecimal(_minStake).multiply(_bigUnit).value;
		_maxStake = new bigDecimal(_maxStake).multiply(_bigUnit).value;
		// console.log(Number(_timeCanUnlock * (24 * 60 * 60)).toFixed(),
		// _percentWithdraw,
		// _percentCommissionF1,
		// _minStake,
		// _maxStake,
		// _percentProfit,
		// _maxOut,
		// _receiveAddress);
		return await contract.methods.editDefi(
			Number(_timeCanUnlock * (24 * 60 * 60)).toFixed(),
			_percentWithdraw,
			_percentCommissionF1,
			_minStake,
			_maxStake,
			_percentProfit,
			_maxOut,
			_receiveAddress
		).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const createStaking = async (
	tokenAdd,
	_timeCanUnlock,
	_percentWithdraw,
	_percentCommissionF1,
	_minStake,
	_maxStake,
	_percentProfit,
	_maxOut,
	_pool,
	decimals_,
	receivess
) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_bankstaking, contractCreateBankStaking);
		const userAddress = accounts[0];

		let _bigUnit = new bigDecimal((10 ** decimals_).toString());
		// totalSupply_ = new bigDecimal(totalSupply_).multiply(_bigUnit).value;
		_minStake = new bigDecimal(_minStake).multiply(_bigUnit).value;
		_maxStake = new bigDecimal(_maxStake).multiply(_bigUnit).value;
		_pool = new bigDecimal(_pool).multiply(_bigUnit).value;
		// console.log(tokenAdd,
		// 	_timeCanUnlock,
		// 	_percentWithdraw,
		// 	_percentCommissionF1,
		// 	_minStake,
		// 	_maxStake,
		// 	_percentProfit,
		// 	_maxOut,
		// 	_pool);
		return await contract.methods.createStaking(
			tokenAdd,
			Number(_timeCanUnlock * (24 * 60 * 60)).toFixed(),
			_percentWithdraw,
			_percentCommissionF1,
			_minStake,
			_maxStake,
			_percentProfit,
			_maxOut,
			_pool,
			receivess
		).send({
			from: userAddress,
			value: 0.001 * 1e18
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};

const getMyDepositStake = async () => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		const contract = new web3.eth.Contract(abi_listdepositstake, contractListDepositStake);

		// return await contract.methods.getLaunchpadsByOwner(accounts[0]).call()
		let data_Ll = await contract.methods.getBuysByOwner(accounts[0]).call()

		const obj = {};

		data_Ll.forEach(item => {
			const stakeAddress = item.stakeAddress;
			if (!obj[stakeAddress]) {
				obj[stakeAddress] = item.owner_;
			}
		});
		const result = Object.keys(obj).map(stakeAddress => ({
			stakeAddress,
			owner_: obj[stakeAddress],
		}));

		let normalLocks = await Promise.all(result.map(async (_dt) => {
			let _dataIc = await getStakeNotToken(_dt?.stakeAddress);
			if (_dataIc) {
				_dataIc.stakeAddress = _dt?.stakeAddress;
				_dataIc.tokenAddress = _dataIc._joinToken;
				return _dataIc;
			}
		}));

		normalLocks = normalLocks.reverse()
		return normalLocks;
	} catch (error) {
		console.error('Error contributing:', error);
		return [];
	}
};


const investStake = async (
	stakeAddress,
	amount,
	ref,
	decimal_
) => {
	try {
		// const web3 = new Web3(Web3.givenProvider);
		const web3 = await getWeb3Provider(Web3.givenProvider);

		let accounts = await web3.eth.getAccounts();
		if (!accounts.length) {
			accounts = ["0x0000000000000010000000000000000000000001"]
		}
		if (!isValidAddress(ref)) {
			ref = "0x0000000000000000000000000000000000000000"
		}

		const contract = new web3.eth.Contract(abi_childStake, stakeAddress);
		const userAddress = accounts[0];

		let _bigUnit = new bigDecimal((10 ** decimal_).toString());
		amount = new bigDecimal(amount).multiply(_bigUnit).value;

		return await contract.methods.staking(
			amount,
			ref
		).send({
			from: userAddress
		});

	} catch (error) {
		console.error('Error contributing:', error);
		return
	}
};



const withdrawStake = async function (stakeAddress) {
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	const contract = new web3.eth.Contract(
		abi_childStake,
		stakeAddress
	);
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	return await contract.methods
		.withdraw()
		.send({ from: accounts[0] })
		.catch((err) => {
			return false;
		});
};

const unStake = async function (stakeAddress) {
	// console.log(num);
	// return
	// const web3 = new Web3(Web3.givenProvider);
	const web3 = await getWeb3Provider(Web3.givenProvider);

	let accounts = await web3.eth.getAccounts();
	const contract = new web3.eth.Contract(
		abi_childStake,
		stakeAddress
	);
	if (!accounts.length) {
		accounts = ["0x0000000000000010000000000000000000000001"]
	}
	return await contract.methods
		.unStaking()
		.send({ from: accounts[0] })
		.catch((err) => {
			return false;
		});
};

const categoryArr = [
	{
		title: 'All',
		id: 0,
		arrChild: [
			{
				img: "/images/listWeb/1.jpg",
				id: 1
			},
			{
				img: "/images/listWeb/2.jpg",
				id: 2
			},
			{
				img: "/images/listWeb/3.jpg",
				id: 3
			},
			{
				img: "/images/listWeb/4.jpg",
				id: 4
			}
		]
	},
	{
		title: 'Meme coin',
		id: 1,
		arrChild: [
			{
				img: "/images/listWeb/1.jpg",
				id: 1
			},
			{
				img: "/images/listWeb/2.jpg",
				id: 2
			}
		]
	},
	{
		title: 'Technology Solutions',
		id: 2,
		arrChild: [
			{
				img: "/images/listWeb/3.jpg",
				id: 3
			},
			{
				img: "/images/listWeb/4.jpg",
				id: 4
			}
		]
	},
	{
		title: 'Other',
		id: 3
	}

]

export {
	unStake, withdrawStake, getWeb3Provider,
	createBuyBackBabyToken, createAntiBotBABYTOKEN, createBABYTOKEN, createAntiBotLiquidityGeneratorToken, calculateTimeAfterPoolEnds,
	createLiquidityGeneratorToken, createAntiBotStandardToken, createStandardToken, getTotalLaunchpad, getMyListLaunchpad,
	editLock, editLockDescription, addresAntibot, addresBABYTOKENDividendTracker, feeDenominator, getListLaunchpad,
	isValidAddress, formatTimestamp, getLockById, transferLockOwner, renounceLockOwner, searchLaunchpad,
	claimCommission, lockToken, vestingLockToken, formatNumber, listTokenLock, infoTokenLock,
	finalize, cancelPool, updateLaunchpad, contributeWithToken, updateAffiliate, editStake, contractLock, contractLockPink,
	changeSaleType, updateTimePublicSale, addWhitelist, removeWhitelist, createStaking, contractsAllChain, allVarChain,
	getLaunchpad, getTokenLocks, contributeToContract, jsonPares, getListTrending,
	infoLaunchpadSV, unContribute, claimToken, getLaunchpadNotToken, multisendCoin, multisendToken,
	getInfoToken, createLaunchpad, approve, unLock, getInfoTokenLaunchpad, contractFairlaunch, contractLaunchpad,

	getRefUser, getWalletsAndCommissionBalances, getAllLaunchpadSever, contractSender, getInfoTokenBasic,
	getInfoStaking, getTotalStake, getMyListStake, getMyBuyLaunchpad,
	staking, getStakeNotToken, getListStake, getMyDepositStake,
	getInfoUser, getInfoTokenStake,
	addTree, investStake,
	checkApproveStaking,
	submitApproveStaking,
	unStaking,
	withdraw,
	categoryArr
};
